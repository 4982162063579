<template>
  <div class="temp temp_draw">
    <DisplayMode
      class="display_mode"
      typeKey="timerActiveComponent"
    ></DisplayMode>
    <div class="canvas_container" ref="canvasContainerRef">
      <canvas id="editor" ref="canvasRef"></canvas>
    </div>
    <!--       @handleSelectGroup="handleSelectGroup"
      :selectedIdArr="idList"
      @removeGroup="handleremoveGroup" -->
    <grouplist
      :isSetting="false"
      :groupList="groupList"
      expandClass="fold_expand_max"
      :type="1"
      ref="groupListRef"
      :canDelete="false"
      @handleSelectGroup="handleSelectGroup"
      @removeGroup="handleremoveGroup"
    ></grouplist>
  </div>
</template>
<script>
import DisplayMode from "../../components/displayMode.vue";
import grouplist from "@/components/grouplist.vue";
import { getTimerGroupList, getDrawData, editGroupList } from "@/api/machine";
import { TimerEditor } from "../../editor/timerEditor";
import {
  awaitWraper,
  isEmpty,
  showMessage,
  showLoading,
  autoFitView,
} from "@/js/util";
export default {
  data() {
    return {
      groupList: [],
      isFileExist: false, //区分是否有图纸资源
      editor: null,
      serial_number: "",
      websock: null, //websocket对象
      isCheckIng: false,
      checkTable: false,
      noDataTable: false,
      unit: 0,
      tempList: [],
      createdDataMap: null,
      curSelect: [],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    // 获取分组列表
    // sync 0不同步 1 同步
    async getGroupListFunc(sync = 1) {
      const token = localStorage.getItem("token");
      await getTimerGroupList(
        {
          serial_number: this.$route.query.id,
          sync,
        },
        token
      ).then((res) => {
        this.groupList = res?.data ?? [];
      });
    },
    updateGroupName() {
      // 修改点位分组名
      for (let { name, numbers } of this.groupList) {
        for (let number of numbers) {
          const target = this.createdDataMap?.get(number);
          if (target) {
            const {
              farbicData: {
                startGroup: { groupNameText },
              },
              initProps,
            } = target;
            groupNameText.set({
              text: name,
            });
            initProps.groupName = name;
          }
        }
      }
      this.editor.canvas.requestRenderAll();
    },
    initCanvas(canvasSize) {
      this.editor = new TimerEditor(
        "editor",
        this.$refs.canvasContainerRef,
        canvasSize
      );
      window.timerEditor = this.editor;
      this.editor.initEventListener("object:moving", this.onObjectMoving);
      this.editor.initEventListener("mouse:down", this.onMouseDownHandler);
      this.editor.initEventListener("mouse:move", this.onMouseMoveHandler);
      this.editor.initEventListener("mouse:up", this.onMouseUpHandler);
      this.editor.initEventListener("mouse:wheel", this.onMouseWheelHandler);
    },
    onObjectMoving(e) {
      if (isEmpty(e.target)) return;
      if (this.createdDataMap?.has(e.target.id)) {
        const { groupData, end, line } = this.getRelatedObj(e.target.id);
        this.updateLinePos(groupData, end, line);
      }
    },
    onMouseDownHandler(e) {
      if (e.e.altKey) {
        this.editor.isDragging = true;
        this.editor.lastPosX = e.e.clientX;
        this.editor.lastPosY = e.e.clientY;
        return;
      }
      if (isEmpty(e.target)) return;
      if (e.target.name == "backgroundImage") {
        this.editor.isDragging = true;
        this.editor.lastPosX = e.e.clientX;
        this.editor.lastPosY = e.e.clientY;
        return;
      }
      const curTouchItem = e.target;
      let target = this.createdDataMap?.get(curTouchItem.id);
      if (!target) return;
      this.editor.canvas.bringToFront(target.farbicData.startGroup.groupData);
      // 区分单选和多选模式
      // if (isMultiple.value) {
      //   //多选模式下
      //   target.initStatus = target.isSelected;

      //   if (!target.isSelected) {
      //     target.isSelected = !target.isSelected;
      //     curSelect.value.push(target.farbicData.startGroup.groupData);
      //     updateBoxColor(target.farbicData.startGroup.wrapperBox, target);
      //     setMultipleSelect();
      //   }
      // } else {
      // 如果没选中 按下默认选中
      target.initStatus = target.isSelected;
      if (!target.isSelected) {
        for (let [key, value] of this.createdDataMap?.entries()) {
          if (key == curTouchItem.id) {
            value.isSelected = !value.isSelected;
            // 更新 选中样式
            this.updateBoxColor(value.farbicData.startGroup.wrapperBox, value);
            this.curSelect = [value.initProps.id];
          } else {
            value.isSelected = false;
            this.updateBoxColor(value.farbicData.startGroup.wrapperBox, value);
          }
        }
      }
      // }
    },
    onMouseMoveHandler(e) {
      if (this.editor.isDragging) {
        var e = e.e;
        var vpt = this.editor.canvas.viewportTransform;
        vpt[4] += e.clientX - this.editor.lastPosX;
        vpt[5] += e.clientY - this.editor.lastPosY;
        this.editor.canvas.requestRenderAll();
        this.editor.lastPosX = e.clientX;
        this.editor.lastPosY = e.clientY;
        return;
      }
      if (!e.target) return;
      if (e.target.name == "backgroundImage") return;
      const curTouchItem = e.target;
      let target = this.createdDataMap?.get(curTouchItem.id);
      target.isMoved = true; //需不需要考虑加个延迟
    },
    onMouseUpHandler(e) {
      if (this.editor.isDragging) {
        this.editor.canvas.setViewportTransform(
          this.editor.canvas.viewportTransform
        );
        this.editor.isDragging = false;
        return;
      }
      if (!e.target) return;
      if (e.target.name == "backgroundImage") return;

      const curTouchItem = e.target;
      let target = this.createdDataMap?.get(curTouchItem.id);
      if (!target.isMoved) {
        if (target.initStatus) {
          target.isSelected = !target.isSelected;
          this.updateBoxColor(target.farbicData.startGroup.wrapperBox, target);
          this.curSelect = [];
        }
      } else {
        target.isMoved = false;
      }
    },
    onMouseWheelHandler(opt) {
      var delta = opt.e.deltaY;
      var zoom = this.editor.canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.1) zoom = 0.1;
      this.editor.canvas.zoomToPoint(
        { x: opt.e.offsetX, y: opt.e.offsetY },
        zoom
      );
      opt.e.preventDefault();
      opt.e.stopPropagation();
      this.editor.canvas.requestRenderAll();
    },

    //
    // 加载图纸文件资源
    async loadDrawFile({ url, name, timer_content, suffix }, tempList) {
      // 如果没有点位数据  只展示背景图
      this.createdDataMap = new Map();
      if (isEmpty(timer_content)) {
        this.initCanvas();
        if (suffix !== "pdf") {
          await this.editor.loadImageByUrl(url, name);
        } else {
          await this.editor.loadPdf(url, name);
        }
        return;
      }
      const parsedContent = JSON.parse(timer_content);
      const {
        sizeProps: { canvasSize },
        posArr,
      } = parsedContent;
      this.initCanvas(canvasSize);
      // 加载背景图
      // url =
      //   "https://img0.baidu.com/it/u=3746055643,3196454000&fm=253&fmt=auto&app=120&f=JPEG?w=1268&h=722";
      if (suffix !== "pdf") {
        await this.editor.loadImageByUrl(url, name);
      } else {
        await this.editor.loadPdf(url, name);
      }
      let backGroundInstance = this.editor.backgroundInstance;
      const { sizeProps, posArr: paneProps } = JSON.parse(timer_content);
      const imgPos = {
        x: backGroundInstance.left,
        y: backGroundInstance.top,
      };
      //
      paneProps.forEach((paneData) => {
        const { id, lineStartPos, lineEndPos, ...otherProps } = paneData;
        const target = tempList.find((item) => item.number == id);
        const targetGroup = this.groupList.find(
          (groupItem) => groupItem.numbers.indexOf(id) > -1
        );
        const mergeProps = {
          ...otherProps,
          id,
          ...{
            groupName: targetGroup?.name ?? "--",
            mode: target.mode,
            open1: target.first_phase.open,
            open2: target.second_phase.open,
            delay1: target.first_phase.delay,
            delay2: target.second_phase.delay,
            initColor: "#353535",
            selectedColor: "#99D3F1",
            labelColor: targetGroup?.color ?? "",
            initShineColor: "#6b6f7b",
          },
        };
        const createdData = this.editor.createFullDataBySavedData(
          {
            x: lineStartPos.x + imgPos.x,
            y: lineStartPos.y + imgPos.y,
          },
          {
            x: lineEndPos.x + imgPos.x,
            y: lineEndPos.y + imgPos.y,
          },
          mergeProps
        );
        // // // 初始化数据时 要保存对应的信息
        this.createdDataMap.set(id, {
          farbicData: createdData,
          initProps: mergeProps,
          isSelected: false,
          initStatus: true,
        });
      });
      // this.editor.canvas.setViewportTransform([
      //   this.editor.columnPer,
      //   0,
      //   0,
      //   this.editor.rowPer,
      //   0,
      //   0,
      // ]);
      autoFitView(this.editor.canvas);
      //画布恢复初始状态
    },
    // 重新处理顺序
    handleZoneData(res, version) {
      if (!Array.isArray(res.data) || !res?.data?.length) return [];
      if (!version) return res.data;
      // 先将数组数据按照顺序排列好  负载切换 顺序会乱
      res.data = res.data
        .map((item) => {
          let { group_number } = item;
          const [pre, next] = group_number.split("-");
          const point = (Number(pre) - 1) * 4 + Number(next);
          item.point = point;
          return item;
        })
        .sort((a, b) => a.point - b.point);
      let length = res.data.length;
      //  如果点位是12的倍数
      let newArr = [1, 5, 9, 2, 6, 10, 3, 7, 11, 4, 8, 12];
      let diff = length % 12;
      let targetArr = res.data.slice(0, diff).map((item) => {
        item.name = item.order_number;
        return item;
      });
      let baseNum = 12;
      let arr = res.data.slice(diff);
      for (let i = 0; i < arr.length; i++) {
        let current = arr[i];
        let tempPoint = current.point - diff;
        let idx = newArr.findIndex((item) => {
          return item == tempPoint % baseNum;
        });
        let num = Math.floor(tempPoint / baseNum) * baseNum + idx + diff;
        if (num + 1 < 10) {
          current.name = "00" + (num + 1);
        } else if (num + 1 >= 10 && num + 1 < 100) {
          current.name = "0" + (num + 1);
        } else {
          current.name = num + 1;
        }
        targetArr[num] = current;
      }
      return targetArr;
    },
    //
    initWebSocket() {
      // 初始化weosocket
      const token = localStorage.getItem("token");
      const lang = localStorage.getItem("language");
      const wsuri =
        "wss://api.yudotmc.com/rtk?token=" + token + "&language=" + lang;
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
      this.websock.onsend = this.websocketsend;
    },
    websocketonopen() {
      // 连接建立之后执行send方法发送数据
      const params = {
        channel: "detail",
        data: {
          serial_number: this.$route.query.id,
        },
      };
      this.websocketsend(JSON.stringify(params));
    },
    websocketonerror() {
      // 连接建立失败重连
    },
    websocketsend(Data) {
      // 数据发送
      this.websock.send(Data);
    },
    websocketclose(e) {
      // 关闭
    },
    websocketonmessage(e) {
      // 数据接收
      const redata = JSON.parse(e.data);
      if (redata.code === 10074) {
        // 断开连接
        this.$message({
          showClose: true,
          message: this.$t("Tip.NetCorrect"),
          type: "warning",
          duration: 1000,
        });
        this.$router.push({ path: "/Machine" });
      }
      if (redata.data.data.mold_check) {
        this.isCheckIng = true;
        if (!localStorage.getItem("serial")) {
          localStorage.setItem("serial", this.$route.query.serial_number);
        }

        if (localStorage.getItem("Temp").length > 0) {
          if (
            localStorage.getItem("serial") &&
            localStorage.getItem("serial") === this.$route.query.serial_number
          ) {
            this.tempList = JSON.parse(localStorage.getItem("Temp"));
            this.updateCanvasData(this.tempList);
          } else {
            this.checkTable = true;
            this.noDataTable = false;
            localStorage.setItem("Timer", "");
            localStorage.setItem("Spm", "");
          }
        } else {
          this.checkTable = true;
          this.noDataTable = false;
          localStorage.setItem("Timer", "");
          localStorage.setItem("Spm", "");
        }
        return;
      }
      if (
        !redata.data.data.mold_check &&
        redata.data.data.temperature_control
      ) {
        if (this.tempList.length > 0) {
          this.checkTable = false;
        }
        this.isCheckIng = false;
        this.noDataTable = false;
        // this.isHidden = true
        if (redata.data.data) {
          redata.data.data.temperature_control = this.handleZoneData(
            {
              data: redata.data.data.temperature_control,
            },
            redata.data.data.standard_tmc_version
          );
          this.tempList = redata.data.data.temperature_control
            ? redata.data.data.temperature_control
            : [];

          this.unit = redata.data.data.unit;

          // 遍历 更新 数据
          this.updateCanvasData(this.tempList);
          if (!localStorage.getItem("serial")) {
            localStorage.setItem(
              "Temp",
              redata.data.data.temperature_control
                ? JSON.stringify(redata.data.data.temperature_control)
                : []
            );
          }

          if (
            localStorage.getItem("serial") &&
            localStorage.getItem("serial") === this.$route.query.serial_number
          ) {
            localStorage.setItem(
              "Temp",
              redata.data.data.temperature_control
                ? JSON.stringify(redata.data.data.temperature_control)
                : []
            );
          }

          localStorage.setItem(
            "Timer",
            redata.data.data.sequencer
              ? JSON.stringify(redata.data.data.sequencer)
              : []
          );
          localStorage.setItem(
            "Spm",
            redata.data.data.shifter
              ? JSON.stringify(redata.data.data.shifter)
              : []
          );
        }

        if (redata.code === 0) {
          this.tempList = [];
          this.noDataTable = true;
          this.checkTable = false;
        }
      }
    },
    updateCanvasData(tempList) {
      // 防空
      tempList.forEach((tempItem) => {
        if (this.createdDataMap?.has(tempItem.order_number)) {
          // 更新对应的数据
          let target = this.createdDataMap.get(tempItem.order_number);
          const {
            farbicData: {
              startGroup: { wrapperBox, svText, pvText },
            },
            initProps,
          } = target;
          // 转换温度
          tempItem["target_temperature"] = this.handleTemperature(
            tempItem["target_temperature"],
            this.unit,
            "sv"
          );
          tempItem["temperature"] = this.handleTemperature(
            tempItem["temperature"],
            this.unit,
            "pv"
          );
          svText.set({
            text: `${this.$t("Temp.Target")} ${tempItem["target_temperature"]}`,
          });
          pvText.set({
            text: `${tempItem["temperature"]}${this.unit == 0 ? "℃" : "℉"}`,
          });
          // 更新颜色
          initProps.initColor = this.getBgColorByTemp(tempItem);
          if (!target.isSelected) {
            // 未被选中的时候才去更新状态色
            this.updateBoxColor(wrapperBox, target);
          }
        }
      });
      this.editor.canvas.requestRenderAll();
    },
    updateBoxColor(
      targetBox,
      { isSelected, initProps: { initColor, selectedColor } }
    ) {
      if (isSelected) {
        targetBox.set({
          // opacity: "0.95",
          fill: selectedColor,
        });
      } else {
        targetBox.set({
          fill: initColor,
          // opacity: 1,
        });
      }
      this.editor.canvas.requestRenderAll();
    },
    updateLinePos(start, end, line) {
      const startCenterPoint = start.getCenterPoint();
      const endCenterPoint = end.getCenterPoint();
      const angle = this.editor.calculateAngle(
        startCenterPoint,
        endCenterPoint
      );
      const distance = this.editor.calcuDistance(
        startCenterPoint,
        endCenterPoint
      );

      line.set({
        x1: startCenterPoint.x,
        y1: startCenterPoint.y,
        x2: startCenterPoint.x + distance,
        y2: startCenterPoint.y,
        angle,
      });
    },
    getRelatedObj(id) {
      const target = this.createdDataMap?.get(id);
      // 可再返回需要的数据 文字 后面再改
      const {
        farbicData: {
          startGroup: { groupData },
          end,
          line,
        },
        initProps,
      } = target;

      return {
        groupData,
        end,
        line,
        initProps,
      };
    },
    // 处理温度
    handleTemperature(temp, unit, key) {
      // 返回 0.0
      if (temp == "0.0") {
      }
      if (unit == 0) {
        if (key == "pv") {
          return temp;
        } else {
          return Math.round(temp);
        }
      } else {
        if (key == "pv") {
          return celsiusToFahrenheit(temp).toFixed(1);
        } else {
          return Math.round(celsiusToFahrenheit(temp));
        }
      }
    },
    getBgColorByTemp(item, idList = []) {
      return item.power == 0
        ? idList.length > 0 && idList.indexOf(item.order_number) != -1
          ? "#92B5CB"
          : "#797979"
        : item.status == 1 ||
          item.status == 2 ||
          item.status == 3 ||
          item.status == 4 ||
          item.status == 0 ||
          item.status == 6
        ? Math.abs(item.temperature - item.target_temperature) <= 2
          ? idList.length > 0 && idList.indexOf(item.order_number) != -1
            ? "#7BCBAF"
            : "#6CD15D"
          : idList.length > 0 && idList.indexOf(item.order_number) != -1
          ? "#c3b17f"
          : "#e39629"
        : parseInt(item.status) > 11
        ? idList.length > 0 && idList.indexOf(item.order_number) != -1
          ? "#BCA08F"
          : "#EB5C0F"
        : idList.length > 0 && idList.indexOf(item.order_number) != -1
        ? "#c3b17f"
        : " #6CD15D";
    },
    handleSelectGroup(selectGroup) {
      // 取消所有的选中
      this.cancelAllSelect();
      // curSelect = []
      for (let group of selectGroup) {
        for (let number of group.numbers) {
          const target = this.createdDataMap?.get(number);
          if (!target) continue;
          target.isSelected = true;
          this.updateBoxColor(target.farbicData.startGroup.wrapperBox, target);
          this.curSelect.push(number);
        }
      }
      this.editor.canvas.requestRenderAll();
    },
    cancelAllSelect() {
      for (let item of this.curSelect) {
        let target = this.createdDataMap?.get(item);
        target.isSelected = false;
        this.updateBoxColor(target.farbicData.startGroup.wrapperBox, target);
      }
    },
    handleremoveGroup(groupItem) {
      const token = localStorage.getItem("token");
      editGroupList(
        {
          action: "del",
          group_name: groupItem.name,
          numbers: groupItem.numbers,
          serial_number: this.$route.query.id,
        },
        token
      ).then(() => {
        // 提示删除成功
        showMessage(this.$t("Setting.RemovedSuccessfully"), "success");
        // 刷新组的 接口
        this.getGroupListFunc(2);
        for (let number of groupItem.numbers) {
          const target = this.createdDataMap?.get(number);
          const {
            farbicData: {
              startGroup: { groupNameText },
            },
            initProps,
          } = target;
          initProps.groupName = "--";
          groupNameText.set({
            text: "--",
          });
        }
        this.editor.canvas.requestRenderAll();
      });
    },
  },
  components: { DisplayMode, grouplist },
  async mounted() {
    if (isEmpty(localStorage.getItem("Timer"))) return;
    const loading = showLoading();
    const tempList = JSON.parse(localStorage.getItem("Timer"));
    this.serial_number = this.$route.query.id;
    const token = localStorage.getItem("token");
    const [err, { data: drawData }] = await awaitWraper(
      getDrawData(this.serial_number, token)
    );
    if (err) {
      // 展示无数据
      loading?.close();
      return;
    }
    await this.getGroupListFunc();

    await this.loadDrawFile(drawData, tempList);
    loading?.close();
    // this.initWebSocket();
    // 因为点位要等待websocket数据 所以先展示已有的数据
    // 然后 websocket推送过来数据后再动态更新
    // this.updateGroupName();
  },
  beforeDestroy() {
    this.websock && this.websock.close();
    if (!isEmpty(this.editor)) {
      this.editor.removeEventListener("object:moving", this.onObjectMoving);
      this.editor.removeEventListener("mouse:down", this.onMouseDownHandler);
      this.editor.removeEventListener("mouse:move", this.onMouseMoveHandler);
      this.editor.removeEventListener("mouse:up", this.onMouseUpHandler);
      this.editor.removeEventListener("mouse:wheel", this.onMouseWheelHandler);
    }
  },
};
</script>
<style scoped>
.temp_draw {
  position: relative;
  .display_mode {
    display: flex;
    gap: 20px;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 10;
  }
  .canvas_container {
    width: 100%;
    height: 100%;
  }
}
</style>
