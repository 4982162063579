<template>
  <div class="temp_apply" @click="handleSwitchMode">
    <span>{{
      $store.state[typeKey]
        ? $t("FileDisplay.textMode")
        : $t("FileDisplay.drawingMode")
    }}</span>
    <el-switch
      :active-value="1"
      :inactive-value="0"
      v-model="$store.state[typeKey]"
      @change="changeMode"
    ></el-switch>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    typeKey: {
      type: String,
      default: "tempActiveComponent",
    },
  },
  mounted() {},
  computed: {},
  methods: {
    handleSwitchMode() {},
    changeMode(val) {
      console.log(this.$store.state[this.typeKey]);
    },
  },
};
</script>
<style scoped></style>
