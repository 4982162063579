const { fabric, pdfjsLib, pdf } = window;
import i18n from "@/lang";
export class TempEditor {
  #infoPaneWidth = 80;
  #fontSize;
  constructor(domId, parentNode, pcProps) {
    // 使用pc的尺寸设置canvas的大小
    this.domId = domId;
    this.parentNode = parentNode;
    this.parentNodeClients = parentNode.getBoundingClientRect();
    this.pcProps = pcProps || this.parentNodeClients;
    this.init();
    this.#fontSize = 16;
    this.isDragging = false;
    this.lastPosX = null;
    this.lastPosY = null;
  }
  getCanvas() {
    return this.canvas;
  }
  init() {
    this.canvas = new fabric.Canvas(this.domId, {
      // 元素对象被选中时保持在当前z轴，不会跳到最顶层
      preserveObjectStacking: true,
      width: this.pcProps.width,
      height: this.pcProps.height,
      enablePointerEvents: true,
      selection: false,
      backgroundColor: "#DFDFE1",
      renderOnAddRemove: false,
    });
    this.columnPer = this.parentNodeClients.width / this.pcProps.width;
    this.rowPer = this.parentNodeClients.height / this.pcProps.height;
    // this.canvas.setViewportTransform([columnPer, 0, 0, rowPer, 0, 0]); //画布恢复初始状态

    return this;
  }
  // 初始化监听事件
  initEventListener(events, cb) {
    this.canvas.on(events, cb);
  }
  // 移除监听事件
  removeEventListener(events, cb) {
    this.canvas?.off?.(events, cb);
  }
  onObjectMoving(e) {
    // 区分编辑模式和非编辑模式
  }
  destroy() {}

  getPreClients(pre) {
    return {
      height: pre.height,
      width: pre.width,
      bl: pre.lineCoords.bl,
    };
  }
  loadPdfImg(img, width, height) {
    const fabricImg = new fabric.Image(img, {
      left: -100,
      top: -100,
      filters: [],
      width,
      height,
      hasBorders: false,
      hasControls: false,
      selectable: true,
      name: "backgroundImage",
      shadow: {},
      dirty: true,
      lockMovementX: true,
      lockMovementY: true,
    });
    //调整图片的大小 以及画布的放大比例  让图片自适应展示
    const imgRatio = fabricImg.height / fabricImg.width;
    const parRatio = this.pcProps.height / this.pcProps.width;
    if (imgRatio < parRatio) {
      const h = this.pcProps.width * imgRatio * 0.9;
      fabricImg.scaleToHeight(h);
      fabricImg.set({
        top: this.pcProps.height / 2 - fabricImg.getBoundingRect().height / 2,
        left: this.pcProps.width / 2 - fabricImg.getBoundingRect().width / 2,
      });
    } else {
      const w = ((this.pcProps.width * parRatio) / imgRatio) * 0.9;
      fabricImg.scaleToWidth(w);
      fabricImg.set({
        top:
          this.pcProps.height / 2 - fabricImg.getBoundingRect().height / 2,
        left:
          this.pcProps.width / 2 - fabricImg.getBoundingRect().width / 2,
      });
    }

    // this.backgroundImageDataUrl = fabricImg.toDataURL({
    //   encoderOptions: 1,
    // });
    this.backgroundInstance = fabricImg;
    fabricImg.set({
      shadow: {
        color: "rgba(0, 0, 0, 0.4)",
        offsetX: 4,
        offsetY: 16,
        blur: 40,
      },
    });
    this.canvas.add(fabricImg);
    // 另外一种center方式 但是得要设置 originX originY 为center
    // setTimeout(() => {
    //   fabricImg.center();
    //   fabricImg.setCoords();
    // }, 1000);
    this.canvas.sendToBack(fabricImg);
    return fabricImg;
  }
  async loadPdf(pdfUrl, fileName) {
    const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
    const page = await pdf.getPage(1);
    const temporaryCanvas = document.createElement("canvas");
    const context = temporaryCanvas.getContext("2d");
    const viewport = page.getViewport({ scale: 1 });
    temporaryCanvas.width = viewport.width;
    temporaryCanvas.height = viewport.height;
    await page.render({ canvasContext: context, viewport }).promise;
    const pdfImage = this.loadPdfImg(
      temporaryCanvas,
      viewport.width,
      viewport.height
    );
    if (fileName) {
      this.fileName = new fabric.IText(
        `${i18n.t("FileDisplay.FileName")}: ${fileName}`,
        {
          fill: "#5B5434",
          fontSize: this.#fontSize,
          left: pdfImage.left,
          top: pdfImage.top - 20,
          name: "fileName",
          dirty: true,
          selectable: false,
          hasControls: false,
        }
      );
      this.canvas.add(this.fileName);
    }
    return pdfImage;
  }
  loadImageByUrl(url, fileName) {
    //
    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        url,
        // "http://192.168.3.218:3120/picture_path/75d6a32b8f030374%20(copy).png",
        // "https://img1.baidu.com/it/u=2628517454,3112868049&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500",
        (img) => {
          //调整图片的大小 以及画布的放大比例  让图片自适应展示
          const imgRatio = img.height / img.width;
          const parRatio = this.pcProps.height / this.pcProps.width;
          if (imgRatio < parRatio) {
            const h = this.pcProps.width * imgRatio * 0.9;
            img.scaleToHeight(h);
            img.set({
              top: this.pcProps.height / 2 - img.getBoundingRect().height / 2,
              left: this.pcProps.width / 2 - img.getBoundingRect().width / 2,
            });
          } else {
            const w = ((this.pcProps.width * parRatio) / imgRatio) * 0.9;
            img.scaleToWidth(w);
            img.set({
              top:
                this.pcProps.height / 2 - img.getBoundingRect().height / 2,
              left:
                this.pcProps.width / 2 - img.getBoundingRect().width / 2,
              lockMovementX: true,
              lockMovementY: true,
            });
          }

          this.canvas.add(img);
          // this.backgroundImageDataUrl = img.toDataURL({
          //   encoderOptions: 1,
          // });
          this.backgroundInstance = img;
          this.canvas.sendToBack(img);
          img.set({
            shadow: {
              color: "rgba(0, 0, 0, 0.4)",
              offsetX: 4,
              offsetY: 16,
              blur: 40,
            },
            name: "backgroundImage",
            hasBorders: false,
            hasControls: false,
            selectable: true,
            dirty: true,
          });
          if (fileName) {
            this.fileName = new fabric.IText(
              `${i18n.t("FileDisplay.FileName")}: ${fileName}`,
              {
                fill: "#5B5434",
                fontSize: this.#fontSize,
                left: img.left,
                top: img.top - 20,
                name: "fileName",
                dirty: true,
                hasControls: false,
                selectable: false,
              }
            );
            this.canvas.add(this.fileName);
          }

          resolve(img);
        }

        // { crossOrigin: "Anonymous" }
      );
    });
  }

  setFileName(name) {
    const img = this.getBackgroundInstance();
    this.fileName = new fabric.IText(
      `${i18n.t("FileDisplay.FileName")}: ${name}`,
      {
        fill: "#5B5434",
        fontSize: this.#fontSize,
        left: img.left,
        top: img.top - 20,
        name: "fileName",
        dirty: true,
        selectable: false,
        hasControls: false,
      }
    );
    this.canvas.add(this.fileName);
  }
  changeFileName(name) {
    this.fileName.set({
      text: `${i18n.t("FileDisplay.FileName")}: ${name}`,
    });
    this.canvas.requestRenderAll();
  }

  // 拖拽生成group信息面板  大尺寸
  createNormalGroupPane({ x, y }, source) {
    const text1 = new fabric.IText(source.groupName, {
      fill: "#FFFCEF",
      fontSize: this.#fontSize,
      left: x + 10,
      top: y + 12,
      fontWeight: 700,
      name: "groupName",
      dirty: true,
    });
    const pre = this.getPreClients(text1);
    const text2 = new fabric.IText(source.name ?? "--", {
      fill: "#5B5434",
      fontSize: this.#fontSize,
      left: x + 10,
      top: pre.bl.y,
      name: "id",
      dirty: true,
    });
    const pre3 = this.getPreClients(text2);
    const text3 = new fabric.IText(source.pv, {
      fill: "#3B3720",
      fontSize: this.#fontSize,
      left: x + 10,
      fontWeight: 700,
      top: pre3.bl.y + 5,
      name: "curTemp",
      dirty: true,
    });
    const pre4 = this.getPreClients(text3);
    const text4 = new fabric.IText(`${i18n.t("Temp.Target")} ${source.sv}`, {
      fill: "#5B5434",
      fontSize: this.#fontSize,
      left: x + 10,
      top: pre4.bl.y,
      name: "targetTemp",
      dirty: true,
    });
    const maxWidth = Math.max(
      this.#infoPaneWidth,
      text1.getScaledWidth(),
      text2.getScaledWidth(),
      text3.getScaledWidth(),
      text4.getScaledWidth()
    );
    const minHeight = text4.top - y + text4.getScaledHeight();

    const rect = new fabric.Rect({
      left: x, // 距离容器顶部 100px
      top: y, // 距离容器左侧 100px
      fill: source.initColor, // 填充 橙色
      width: maxWidth + 20, // 宽度 100px
      height: minHeight + 10, // 高度 100px
      rx: 10, // x轴的半径
      ry: 10, // y轴的半径
      name: "wrapperBox",
      dirty: true,

      // opacity: 0.6,
    });
    const group = new fabric.Group([rect, text1, text2, text3, text4], {
      id: source.id,
      name: "infoPane",
      selectionRadius: 10,
      hasControls: false,
      hasBorders: false,
      // selectStatus: true,
      opacity: 0.9,
      shadow: {
        color: "rgba(0, 0, 0, 0.2)",
        offsetX: 1,
        offsetY: 10,
        blur: 10,
      },
      dirty: true,
    });
    return {
      // ...group,

      groupNameText: text1,
      idText: text2,
      pvText: text3,
      svText: text4,
      wrapperBox: rect,
      groupData: group,
    };
  }

  // 小尺寸信息面板
  createSmallGroupPane({ x, y }, source) {
    const group = this.createNormalGroupPane({ x, y }, source);
    group.svText.set({
      opacity: 0,
    });
    group.wrapperBox.set({
      height: group.wrapperBox.height - group.svText.getScaledHeight(),
    });
    return group;
  }
  createCircle({ x, y }, source) {
    return new fabric.Circle({
      radius: source.circleWidth,
      fill: source.circleColor,
      left: x - 13,
      top: y - 13,
      hasControls: false,
      id: source.id,
      name: "endCircle",
      // selectionRadius: this.#circleWidth,
      hasBorders: false,
      opacity: 0.8,
      dirty: true,
      // originX: "center",
      // originY: "center",
    });
  }
  calculateAngle({ x: x1, y: y1 }, { x: x2, y: y2 }) {
    var angle = (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI;
    if (angle < 0) return angle < 0 ? 360 + angle : angle;
    return angle;
  }
  calcuDistance({ x: x1, y: y1 }, { x: x2, y: y2 }) {
    return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
  }
  createLine(start, end, { id, lineWidth, lineColor }) {
    const leader_line = new fabric.Line(null, {
      stroke: lineColor,
      strokeWidth: lineWidth,
      selectable: false,
      id,
      name: "leader_Line",
      hasControls: false,
      dirty: true,
    });
    const startPos = start.getCenterPoint();
    const endPos = end.getCenterPoint();
    const angle = this.calculateAngle(startPos, endPos);
    const distance = this.calcuDistance(startPos, endPos);
    leader_line.set({
      x1: startPos.x,
      y1: startPos.y,
      x2: startPos.x + distance,
      y2: startPos.y,
      angle,
    });
    return leader_line;
  }
  createFullData(pos, source) {
    // 转换后的坐标
    pos = this.canvas.restorePointerVpt(pos);
    const startGroup = this.createNormalGroupPane(
      { x: pos.x - 150, y: pos.y - 46.5 },
      source
    );
    const { groupData } = startGroup;
    const end = this.createCircle(pos, source);
    const leader_line = this.createLine(groupData, end, source);
    this.canvas.add(leader_line, groupData, end);

    return { startGroup, end, line: leader_line };
  }
  createFullDataBySavedData(startPos, endPos, source) {
    startPos = this.canvas.restorePointerVpt(startPos);
    endPos = this.canvas.restorePointerVpt(endPos);
    const startGroup = this.createNormalGroupPane(
      { x: startPos.x, y: startPos.y },
      source
    );
    const { groupData } = startGroup;
    const end = this.createCircle(endPos, source);
    const leader_line = this.createLine(groupData, end, source);
    this.canvas.add(leader_line, groupData, end);
    return { startGroup, end, line: leader_line };
  }

  setBackgroundInstance(instance) {
    this.canvas.add(instance);
    this.backgroundInstance = instance;
    return this;
  }
  getBackgroundInstance() {
    let target;
    this.canvas.forEachObject((item) => {
      if (item.name == "backgroundImage") {
        target = item;
      }
    });
    return target;
  }
  cloneBackgroundInstance() {
    return new Promise((resolve) => {
      this.getBackgroundInstance().clone(
        (instance) => {
          resolve(instance);
        },
        ["name", "hasBorders", "hasControls"]
      );
    });
  }
}
