<template>
  <component :is="activeComponent"></component>
</template>
<script>
import TextPage from "./Text.vue";
import Draw from "./Draw.vue";
import { mapGetters } from "vuex";

const comConfig = [TextPage, Draw];
export default {
  data() {
    return {};
  },
  components: {
    TextPage,
    Draw,
  },
  mounted() {},
  computed: {
    ...mapGetters(["tempActiveComponent"]),
    activeComponent() {
      return comConfig[this.tempActiveComponent];
    },
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.tips {
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: #8e8c8c;
  padding-bottom: 20px;
}
.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;

  position: absolute;
  flex-direction: column;
  left: 0;
  top: 100px;
  .empty_text {
    line-height: 20px !important;
    font-weight: bold;
    font-size: 16px;
    color: #909399;
  }
}
@media (max-width: 1367px) {
  .temp .temp_icon {
    top: 325px !important;
  }
}
@media (min-width: 1367px) and (max-width: 1919px) {
  .temp .temp_icon {
    top: 365px !important;
  }
}
.dialog /deep/ .el-dialog {
  border-radius: 20px !important;
  background: #e7e9ee;
  margin-top: 30vh !important;
  filter: drop-shadow(0 6px 6px rgba(0, 0, 0, 0.16));
}
.dialog /deep/ .el-dialog__body {
  padding: 0px 20px 10px 20px;
}
.btn-normal {
  width: 70px;
  height: 32px;
  border-radius: 20px;
  font-size: 13px;
  line-height: 34px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  float: right;
  margin-left: 10px;
  cursor: pointer;
}
.dialog /deep/ .el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
}
.dialog /deep/ .plan-form.r3 .label {
  font-size: 16px;
}
.dialog /deep/ .el-input--suffix {
  width: 100% !important;
  padding-right: 15px;
}
.dialog /deep/ .plan-form .row .form-item {
  width: 33.4% !important;
}
.dialog /deep/ .el-dialog__title {
  font-weight: bold;
  font-size: 20px;
}
.dialog /deep/ .el-dialog__body {
  padding: 20px 20px 0 20px !important;
}
.dialog /deep/ .el-dialog__header {
  padding: 20px 20px 10px !important;
  text-align: left;
}
.dialog /deep/ .el-dialog__headerbtn {
  font-size: 30px !important;
  top: 12px;
}
.dialog /deep/ .el-dialog__footer {
  padding: 0px 20px 15px !important;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.form-box {
  .label {
    font-size: 18px;
    font-weight: 400;
    color: #4d4f5c;
    text-align: left;
  }
}
.temp_list::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 2px;
  height: 2px;
  background-color: #fff;
  cursor: pointer;
}
/*定义滚动条轨道 内阴影+圆角*/
.temp_list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px rgba(255, 255, 255, 0.5);
  border-radius: 0px;
  background-color: #fff;
}

/*定义滑块 内阴影+圆角*/
.temp_list::-webkit-scrollbar-thumb {
  border-radius: 0px;
  box-shadow: inset 0 0 0px rgba(255, 255, 255, 0.5);
  background-color: #707070;
}
.temp {
  .confirmActive {
    background: url(../../../../assets/image/rightConfire.png) no-repeat;
    background-size: cover;
    transition: all 0.3s;
  }
  .confirmActive:active {
    opacity: 0.8;
  }
  .confirmActive:hover {
    background: url(../../../../assets/image/confirmHover.png);
    background-size: cover;
  }
  height: calc(100vh - 126px);
  position: relative;
  .temp_control {
    float: left;
    width: 200px;
    position: absolute;
    right: 0;
    top: 0;
    background: #a8abb5;
    box-shadow: -1px 0px 0px 0px #9497a2;
    height: 100%;
    .temp_content {
      width: 100%;
      height: 100%;
      .temp_apply {
        display: flex;
        justify-content: space-between;
        padding: 15px 10px 10px 10px;
        text-align: left;
        color: #4b4c50;
        font-size: 14px;
        /deep/ .el-switch {
          height: 22px !important;
          margin-left: 5px;
        }
        /deep/ .el-switch__core {
          width: 50px !important;
          height: 22px !important;
          background: #62646b !important;
          border: none !important;
          border-radius: 22px;
        }
        /deep/ .el-switch.is-checked .el-switch__core {
          background-color: #4478f3 !important;
        }
        /deep/ .el-switch__core:after {
          content: "";
          position: absolute;
          top: 0px;
          left: 0px;
          transition: all 0.3s;
          width: 22px;
          height: 22px;
          background: #dedfdf;
          border-radius: 22px;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
        }
        /deep/ .el-switch.is-checked .el-switch__core::after {
          top: 0px;
          left: 28px !important;
          margin-left: 0px !important;
          background: #fff !important;
          z-index: 200;
        }
      }
      .group_setting_wrapper {
        padding: 0 10px 20px 10px;
        .group_setting {
          color: #cbd1e2;
          background: #62646b;
          border: 1px solid #62646b;
          border-radius: 4px;
          .group_setting_name {
            font-size: 14px;
            text-align: left;
            padding: 8px 0 0 8px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.14);
            .group_name_title {
              padding: 0;
              margin: 0;
              padding-bottom: 5px;
            }
            .group_name_input {
              /deep/ .el-input__inner {
                width: 100%;
                height: 100%;
                float: left;
                background: #62646b;
                border: 1px solid #62646b;
                text-align: left;
                color: #cbd1e2;
                font-size: 14px;
                cursor: pointer;
                font-size: 14px;
                padding: 0 0 0 0px;
              }
            }
          }
          .group_button_wrapper {
            width: 100%;
            padding: 0px 5px 10px 5px;
            .groupbutton {
              font-size: 12px;
              min-width: 80px;
              text-align: center;
              line-height: 26px;
              height: 26px;
              color: #fff;
              border-radius: 20px;
              cursor: pointer;
              margin-top: 10px;
            }
            .button_primary {
              background: #4478f3;
            }
            .button_primary:active {
              opacity: 0.8;
            }
            .button_info {
              background: #a8abb5;
            }
            .button_info:active {
              opacity: 0.8;
            }
          }
        }
      }
      .setting_wrapper {
        padding: 0 10px;
        height: 120px;
        .content_offset_val {
          // line-height: 52px;
          position: relative;
          height: 42px;
          line-height: 42px;
          text-indent: 55px;
          width: 100%;
          background: #858890;
        }

        .content_offset_val::before {
          content: "";
          width: 14px;
          height: 2px;
          background: #75767c;
          left: 10px;
          top: 16px;
          position: absolute;
        }
        .content_offset_plus::after {
          content: "";
          width: 14px;
          height: 2px;
          background: #75767c;
          left: 10px;
          top: 16px;
          position: absolute;
          rotate: (90deg);
        }
        .value_title {
          font-size: 14px;
          text-align: left;
          color: #4b4c50;
        }
        .confires {
          height: 34px;
          line-height: 34px;
          margin-top: 7px;
          float: left;
          width: 40px;
          background: #62646b;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .confires_confirmed {
          cursor: pointer;
        }
        .confires_loading {
          cursor: not-allowed;
        }
        .value_result {
          height: 34px;
          line-height: 34px;
          margin-top: 7px;
          float: left;
          width: 140px;

          /deep/ .el-input__inner {
            width: 100%;
            height: 100%;
            float: left;
            background: #62646b;
            border: 1px solid #62646b;
            text-align: left;
            color: #cbd1e2;
            font-size: 14px;
            cursor: pointer;
            font-size: 14px;
            padding: 0 0 0 36px;
          }
        }
        .value_top {
          border-radius: 4px 0 0 0;
          /deep/ .el-input__inner {
            border-radius: 4px 0 0 0px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.14);
          }
        }
        .confirm_top {
          border-radius: 0 4px 0 0;
          border-bottom: 1px solid rgba(255, 255, 255, 0.14);
        }

        .value_bottom {
          margin-top: 0;
          border-radius: 0 0 0 4px;
          /deep/ .el-input__inner {
            border-radius: 0 0 0 4px;
          }
          //
        }
        .confirm_bottom {
          margin-top: 0;
          border-radius: 0 0 4px 0;
        }
      }
      .temp_value {
        padding: 0 10px;
        height: 70px;
        .value_title {
          font-size: 14px;
          text-align: left;
          color: #4b4c50;
        }
        .confires {
          height: 34px;
          line-height: 34px;
          border-radius: 0 4px 4px 0;
          margin-top: 7px;
          float: left;
          width: 40px;
          background: #62646b;
          display: flex;
          justify-content: center;
          cursor: pointer;
          align-items: center;
        }
        .value_result {
          height: 34px;
          line-height: 34px;
          border-radius: 4px 0 0 4px;
          margin-top: 7px;
          float: left;
          width: 140px;
          position: relative;
          /deep/ .el-input__inner {
            width: 100%;
            height: 100%;
            float: left;
            background: #62646b;
            border: 1px solid #62646b;
            text-align: left;
            color: #cbd1e2;
            font-size: 14px;
            cursor: pointer;
            border-radius: 4px 0 0 4px;
            font-size: 14px;
          }
        }
      }
    }
  }
  .temp_params {
    background: #a8abb5;
    box-shadow: -1px 0px 0px 0px #9497a2;
    width: 45px;
    color: #6b6e78;
    height: 100%;
    word-wrap: break-word;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: tb-rl;
    padding: 25px;
    display: flex;
    -webkit-display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    position: absolute;
    right: 0;
    text-align: left;
  }
  .temp_icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 425px;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
  }
  .temp_list {
    float: left;
    height: calc(100vh - 101px);
    padding: 25px 5px 30px 20px;
    overflow: auto;
    .temp_item {
      margin-right: 20px;
      height: 140px;
      border-radius: 6px;
      box-sizing: border-box;
      background: rgba(255, 255, 255, 0.64);
      box-shadow: 0px 6px 6px 0px rgba(161, 162, 160, 0.63);
      position: relative;
      cursor: pointer;

      .temp_top {
        background-color: #e39629;
        height: 20px;
        line-height: 20px;
        font-size: 13px;
        color: #fef5e8;
        border-radius: 4px 4px 0 0;
        padding-right: 10px;
        display: flex;
        .temp_top_title {
          width: 50%;
        }
        .temp_top_text {
          width: 50%;
          text-align: right;
          font-size: 13px;
        }
      }
      .temp_con {
        width: 100%;
        padding: 0 10px;
        height: 120px;
        border-radius: 0 0 6px 6px;
        position: relative;
        .item_img {
          width: 100%;
          height: 100%;
          left: 0;
          top: 20px;
          position: absolute;
          display: flex;
          justify-content: center;
          img {
            width: 80px;
            height: 80px;
            z-index: 9999;
          }
        }
        .temp_jk {
          position: absolute;
          right: 10px;
          bottom: 35px;
          width: 16px;
          height: 16px;
          border-radius: 16px;
          font-size: 12px;
          text-align: center;
          line-height: 16px;
          color: #656567;
          background: #afb0b2;
        }
        .temp_pv {
          padding: 10px 0 4px 0;
          font-size: 26px;
          font-weight: 700;
          text-align: left;
          color: #473f3b;
        }
        .temp_No {
          width: 80%;
          // margin-left: 10%;
          right: 10px;
          text-align: right;
          position: absolute;
          top: 5px;
        }
        .temp_target {
          font-size: 12px;
          text-align: left;
          color: #473f3b;
        }
        .temp_sv {
          font-size: 18px;
          font-weight: 700;
          text-align: left;
          color: #473f3b;
          padding: 0px 0px 8px 0;
        }
        .temp_ele {
          font-size: 12px;
          text-align: left;
          color: #473f3b;
          .temp_per,
          .temp_current {
            width: 50%;
            float: left;
            text-indent: 2px;
          }
        }
      }
    }
  }
}
</style>
