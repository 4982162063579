<template>
  <div class="temp">
    <div class="checkBox">
      <el-checkbox
        v-model="isAll"
        :label="$t('Temp.All')"
        @change="changeAll"
      ></el-checkbox>
    </div>
    <div
      class="temp_list"
      :style="{
        width: isHidden ? 'calc(100% - 200px)' : 'calc(100% - 37.5px)',
      }"
    >
      <el-col
        v-for="(item, index) in tempList"
        :key="item.order_number"
        :lg="3"
        :md="3"
        class="card-panel-col"
      >
        <div
          class="temp_item"
          :style="{ margin: isHidden ? '0 20px 15px 0' : '0 32.5px 15px 0' }"
          @click.prevent="selectItem(item, index)"
          @mousedown="moveStart(index, item)"
          @mouseup="moveStop(item)"
        >
          <div
            class="temp_top"
            :style="{
              background:
                item.power == 0
                  ? idList.length > 0 && idList.indexOf(item.order_number) != -1
                    ? '#92B5CB'
                    : '#a8abb5'
                  : item.status == 1 ||
                    item.status == 2 ||
                    item.status == 3 ||
                    item.status == 4 ||
                    item.status == 0 ||
                    item.status == 5 ||
                    item.status == 6
                  ? Math.abs(item.temperature - item.target_temperature) <= 2
                    ? idList.length > 0 &&
                      idList.indexOf(item.order_number) != -1
                      ? '#7BCBAF'
                      : '#54C258'
                    : idList.length > 0 &&
                      idList.indexOf(item.order_number) != -1
                    ? '#c3b17f'
                    : '#e39629'
                  : parseInt(item.status) > 11
                  ? idList.length > 0 && idList.indexOf(item.order_number) != -1
                    ? '#BCA08F'
                    : '#EB5C0F'
                  : idList.length > 0 && idList.indexOf(item.order_number) != -1
                  ? '#c3b17f'
                  : ' #54C258',
            }"
          >
            <div class="temp_top_title">
              {{ item.zone_name }}
            </div>

            <div class="temp_top_text">
              {{
                item.work_mode == 4
                  ? $t("Machine.Test")
                  : item.power == 0
                  ? $t("Machine.Stop")
                  : item.status == 0 || item.status == 1 || item.status == 4
                  ? $t("Machine.Run")
                  : item.status == 2
                  ? $t("Machine.Soft")
                  : item.status == 5
                  ? $t("Machine.Standby")
                  : item.status === 11
                  ? "AL-H"
                  : item.status === 12
                  ? "AL-L"
                  : item.status === 13
                  ? "Tc.Op"
                  : item.status === 14
                  ? "Tc.Re"
                  : item.status === 15
                  ? "Tc.St"
                  : item.status === 16
                  ? "HT.Op"
                  : item.status === 17
                  ? "HT.St"
                  : item.status === 18
                  ? "Tr.St"
                  : item.status === 19
                  ? "Fuse"
                  : item.status === 20
                  ? "GR.ST"
                  : item.status === 21
                  ? "Ht.Re"
                  : item.status === 3
                  ? $t("Machine.Auto")
                  : item.status === 6
                  ? $t("Machine.Manual")
                  : $t("Machine.Run")
              }}
            </div>
          </div>
          <div
            class="temp_con"
            :style="{
              background:
                idList.length > 0 && idList.indexOf(item.order_number) != -1
                  ? '#54B7EB'
                  : '',
            }"
          >
            <div class="temp_pv">
              {{ handleTemperature(item.temperature, unit, "pv") }}
            </div>
            <div class="temp_No">{{ item.name }}({{ item.group_number }})</div>
            <div class="temp_target">{{ $t("Temp.Target") }}</div>
            <div class="temp_sv">
              {{ handleTemperature(item.target_temperature, unit)
              }}{{ unit == 0 ? "℃" : "℉" }}
            </div>
            <div class="temp_jk">{{ item.sensor_model }}</div>
            <div class="temp_ele">
              <div class="temp_per">{{ item.output }}%</div>
              <div class="temp_current" style="text-align: right">
                {{ item.current || item.current === 0 ? item.current : "--" }}A
              </div>
            </div>
            <div class="item_img" v-show="is == index && isGif">
              <img :src="GIF" alt="" />
            </div>
          </div>
        </div>
      </el-col>
    </div>
    <div class="temp_control" v-show="isHidden">
      <div class="temp_content">
        <DisplayMode></DisplayMode>
        <div class="temp_apply" @click="handleSwitch">
          <span>{{ $t("Temp.Apply") }}</span>
          <el-switch
            v-model="apply"
            @change="changeSwitch"
            :disabled="idList.length === 0 || isCheckIng"
          ></el-switch>
        </div>
        <div class="temp_apply">
          <!-- @click="handleChangeSwitch" -->
          <span>{{ $t("Update2.groupSetting") }}</span>
          <el-switch v-model="groupSettingSwitch"></el-switch>
        </div>
        <!-- 分组 -->
        <div class="group_setting_wrapper" v-show="groupSettingSwitch">
          <div class="group_setting">
            <div class="group_setting_name">
              <p class="group_name_title">{{ $t("Update2.groupName") }}</p>
              <el-input
                class="group_name_input"
                placeholder=""
                v-model="groupName"
                ref="groupNameRef"
                @focus="handleGroupNameFocus"
              >
              </el-input>
            </div>
            <div class="group_button_wrapper">
              <div
                v-if="buttonStatus"
                class="groupbutton"
                :class="
                  buttonDisable || idList.length <= 1
                    ? 'button_info'
                    : 'button_primary'
                "
                @click="handleAddGroup"
              >
                {{ $t("Update2.addToGroup") }}
              </div>
              <div
                v-else
                class="groupbutton"
                :class="'button_primary'"
                @click="handleEditGroup"
              >
                {{ $t("Update2.editGroup") }}
              </div>
              <div class="groupbutton button_info" @click="deselect">
                {{ $t("AddMachine.Cancel") }}
              </div>
            </div>
          </div>
        </div>
        <!-- 分组设置 -->
        <div class="temp_value" style="position: relative">
          <div class="value_title">{{ $t("Record.Temperature") }}</div>
          <el-input
            class="value_result"
            placeholder="0"
            v-model.trim="temp"
            @change="tempChanged = true"
            onkeyup="this.value = this.value.replace(/[^A-z0-9]/, '')"
          >
          </el-input>
          <div @click="confire" class="confires">
            <div class="confirmActive" style="width: 30px; height: 24px" />
          </div>
        </div>

        <!-- 温度偏移量 -->
        <div class="setting_wrapper" style="position: relative">
          <div class="value_title">{{ $t("Update2.tempOffset") }}</div>
          <div class="input_wrapper">
            <el-input
              @change="handleOffsetTempChange('incr')"
              class="value_result value_top content_offset_val content_offset_plus"
              v-model="increaseOffset"
              :disabled="offsetLoading"
            >
            </el-input>
            <div
              @click="handleSetOffsetTemp('incr')"
              class="confires confirm_top"
              :class="offsetLoading ? 'confires_loading' : 'confires_confirmed'"
            >
              <div class="confirmActive" style="width: 30px; height: 24px" />
            </div>
          </div>
          <div class="input_wrapper">
            <el-input
              @change="handleOffsetTempChange('decr')"
              class="value_result value_bottom content_offset_val"
              v-model="decreaseOffset"
              :disabled="offsetLoading"
            >
            </el-input>
            <div
              @click="handleSetOffsetTemp('decr')"
              class="confires confirm_bottom"
              :class="offsetLoading ? 'confires_loading' : 'confires_confirmed'"
            >
              <div class="confirmActive" style="width: 30px; height: 24px" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="temp_params" v-show="!isHidden">
      {{ $t("AddMachine.Parameter") }}
    </div>
    <div
      class="temp_icon"
      @click="changeHidden"
      :style="{
        backgroundImage: isHidden
          ? 'url(' + require('../../../../assets/image/temp_right.png') + ')'
          : 'url(' + require('../../../../assets/image/temp_left.png') + ')',
        right: isHidden ? '182px' : '30px',
      }"
    ></div>
    <el-dialog
      :title="$t('Temp.Control')"
      :visible.sync="dialogVisible"
      width="364px"
      class="dialog"
    >
      <el-form ref="workShopForm" :model="workShop" label-width="0px">
        <div class="form-box">
          <el-form-item prop="name">
            <div class="label">{{ $t("TempTab.EquipmentMatchingCode") }}</div>
            <el-input
              v-model.trim="workShop.machineCode"
              :placeholder="$t('Temp.matchingCode')"
              auto-complete="off"
            />
            <div class="tips">{{ $t("Tip.removeTip") }}</div>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div
          class="btn-normal pan-btn blue-btn"
          style="margin-right: 0px; background: #4875ef"
          @click="handleConfirm()"
        >
          {{ $t("Login.OK") }}
        </div>
        <div
          class="btn-normal pan-btn tiffany-btn"
          @click="dialogVisible = false"
          style="background: #767e95"
        >
          {{ $t("AddMachine.Cancel") }}
        </div>
      </span>
    </el-dialog>
    <div v-show="noDataTable" class="noData">
      <img src="../../../../assets/image/emptyData.png" />
      <div class="empty_text">{{ $t("Warning.NoData") }}</div>
    </div>
    <div v-show="checkTable" class="noData">
      <img src="../../../../assets/image/emptyData.png" />
      <div class="empty_text">{{ $t("Tip.isCheck") }}</div>
    </div>

    <grouplist
      :isSetting="isHidden"
      :groupList="groupList"
      :selectedIdArr="idList"
      :type="1"
      @handleSelectGroup="handleSelectGroup"
      @removeGroup="handleremoveGroup"
      ref="groupListRef"
    ></grouplist>
  </div>
</template>

<script>
import GIF from "../../../../assets/image/press.gif";
import grouplist from "@/components/grouplist.vue";
import {
  setTemperature,
  matching,
  validate,
  getGroupList,
  editGroupList,
  setOffsetTemp,
} from "@/api/machine";
import {
  showMessage,
  isInViewPort,
  isEmpty,
  celsiusToFahrenheit,
} from "@/js/util";
import DisplayMode from "../../components/displayMode.vue";
const zone12 = [1, 5, 9, 2, 6, 10, 3, 7, 11, 4, 8, 12];
export default {
  name: "Temp",
  components: { grouplist, DisplayMode },
  data() {
    return {
      isHidden: true,
      temp: "--",
      dialogVisible: false,
      noDataTable: false,
      checkTable: false,
      control: true,
      startPoint: "",
      endPoint: "",
      repeatSwitch: true,
      repeatCode: true,
      repeatTemp: true,
      GIF: "",
      isTest: false,
      unit: 0,
      repeatControl: true,
      repeatCheck: true,
      repeatPower: true,
      isCheckIng: false,
      workShop: {
        machineCode: "",
      },
      apply: false,
      is: "",
      isGif: false,
      websock: null,
      idList: [],
      isCheck: false,
      timer: "",
      tempList: [],
      increaseOffset: "", //温度偏移正数
      decreaseOffset: "", //温度偏移负数
      offsetType: "", //偏移类型
      offsetTemp: "", //温度偏移量
      groupName: "", //组名
      preGroupName: "",
      addGroupButtonDisable: false,
      groupList: [], // 分组列表
      buttonStatus: true, // 两个 button 的显示隐藏
      buttonDisable: false, // 两个button 是否禁用
      showKeyDisabled: false, // 是否可以输入名字
      isSameGroup: false,
      groupSettingSwitch: true, // groupsetting 开关状态
      tempChanged: false, //是否修改了温度
      offsetTempChanged: false, //是否修改了 开关
      offsetLoading: false, //修改温度偏移量给个loading
      isAll: false,
    };
  },
  beforeDestroy() {
    this.websock.close();
  },
  watch: {
    idList(value) {
      if (value.length === 1) {
        this.setParams(value);
        this.$emit("hiddenSelect", true);
      } else if (value.length > 1) {
        this.setAllParams(value);
        this.$emit("hiddenSelect", true);
      } else {
        this.$emit("hiddenSelect", false);
      }
      if (value?.length >= 1) {
        const isAllNewData = value.every((item) => {
          const targetIdx = this.groupList.findIndex((group) =>
            group.numbers.includes(item)
          );
          return targetIdx < 0;
        });

        if (!isAllNewData) {
          let firstGroupIdx = this.groupList.findIndex((group) =>
            group.numbers.includes(value[0])
          );
          let isSameGroup = value.every((res) => {
            const resGroupIdx = this.groupList.findIndex((group) =>
              group.numbers.includes(res)
            );
            return resGroupIdx == firstGroupIdx;
          });
          this.isSameGroup = isSameGroup;
          // 如果都是一个组的
          if (isSameGroup) {
            // 找到归属组的准确数据
            const targetGroup = this.groupList.find((group) =>
              group.numbers.includes(value[0])
            );
            // 数量跟组内容的数量相同
            if (value.length == targetGroup.numbers.length) {
              // alert('已加入过组，可以编辑名字')
              // groupSetting 打开  禁用
              // button 隐藏 禁用
              // name 可以编辑
              this.groupSettingSwitch = true;
              this.buttonStatus = false;
              this.buttonDisable = true;
              this.showKeyDisabled = false;
            } else {
              // groupSetting 打开  禁用
              // button  打开 禁用
              // name 不可以编辑
              this.groupSettingSwitch = true;
              this.buttonStatus = true;
              this.buttonDisable = true;
              this.showKeyDisabled = true;
            }
          } else {
            // groupSetting 打开  禁用
            // button  打开 禁用
            // name 不可以编辑
            this.groupSettingSwitch = true;
            this.buttonStatus = true;
            this.buttonDisable = true;
            this.showKeyDisabled = true;
          }
        } else {
          // 都是新的
          if (value.length == 1) {
            // groupSetting 打开  禁止使用
            // button  打开 禁止
            // name 禁用
            this.groupSettingSwitch = true;
            this.buttonStatus = true;
            this.buttonDisable = true;
            this.showKeyDisabled = false;
          } else {
            // groupSetting 打开  可以使用
            // button  打开 可以使用
            // name 可以编辑
            this.groupSettingSwitch = true;
            this.buttonStatus = true;
            this.buttonDisable = false;
            this.showKeyDisabled = false;
          }
        }
      }
    },
    temp(val) {
      if (val.length > 2 && val.includes("--")) {
        this.temp = val.replace("--", "");
      }
    },
  },
  methods: {
    handleSwitchMode() {},
    changeMode() {},
    // 重新处理顺序
    handleZoneData(res, version) {
      if (!Array.isArray(res.data) || !res?.data?.length) return [];
      if (!version) {
        return res.data
          .map((item) => {
            item.name = item.order_number;
            return item;
          })
          .filter((item) => item.power != -1);
      }
      // 先将数组数据按照顺序排列好  负载切换 顺序会乱
      res.data = res.data
        .map((item) => {
          let { group_number } = item;
          const [pre, next] = group_number.split("-");
          const point = (Number(pre) - 1) * 4 + Number(next);
          item.point = point;
          return item;
        })
        .sort((a, b) => a.point - b.point);
      let length = res.data.length;
      //  如果点位是12的倍数
      let newArr = [1, 5, 9, 2, 6, 10, 3, 7, 11, 4, 8, 12];
      let diff = length % 12;
      let targetArr = res.data.slice(0, diff).map((item) => {
        item.name = item.order_number;
        return item;
      });
      let baseNum = 12;
      let arr = res.data.slice(diff);
      for (let i = 0; i < arr.length; i++) {
        let current = arr[i];
        let tempPoint = current.point - diff;
        let idx = newArr.findIndex((item) => {
          return item == tempPoint % baseNum;
        });
        let num = Math.floor(tempPoint / baseNum) * baseNum + idx + diff;
        current.name = (num + 1).toString().padStart(3, "0");
        targetArr[num] = current;
      }
      return targetArr.filter((item) => item.power != -1);
    },

    // 处理温度
    handleTemperature(temp, unit, key) {
      // 返回 0.0
      if (temp == "0.0") {
      }
      if (unit == 0) {
        if (key == "pv") {
          return temp;
        } else {
          return Math.round(temp);
        }
      } else {
        if (key == "pv") {
          return celsiusToFahrenheit(temp).toFixed(1);
        } else {
          return Math.round(celsiusToFahrenheit(temp));
        }
      }
    },
    changeAll(val) {
      if (!this.control) {
        return;
      }
      if (val) {
        for (var i = 0; i < this.tempList.length; i++) {
          if (parseFloat(this.tempList[i].temperature) !== 0) {
            this.idList.push(this.tempList[i].order_number);
          }
        }
      } else {
        this.deselect();
      }
    },
    deselect() {
      this.idList = [];
      this.temp = "--";
      this.startPoint = "";
      this.endPoint = "";
      this.apply = false;
      this.groupName = "";
      this.buttonStatus = true;
      this.offsetTemp = "";
      this.decreaseOffset = "";
      this.increaseOffset = "";
    },
    sets(tempLs) {
      const resultTemp = tempLs.every(function (computer) {
        return computer.target_temperature === tempLs[0].target_temperature;
      });
      const resultApply = tempLs.some(function (computer) {
        return computer.power === 0;
      });
      const firstGroupIdx = this.groupList.findIndex((group) =>
        group.numbers.includes(tempLs[0].order_number)
      );
      const isSameGroup = tempLs.every((res) => {
        const resGroupIdx = this.groupList?.findIndex((group) =>
          group.numbers.includes(res.order_number)
        );
        return resGroupIdx == firstGroupIdx;
      });
      // 设置 组名
      this.groupName = isSameGroup
        ? this.groupList[firstGroupIdx]?.name ?? "--"
        : "--";
      this.temp = resultTemp
        ? this.handleTemperature(tempLs[0].target_temperature, this.unit)
        : "--";
      this.apply = !resultApply;
      this.offsetTemp = "";
      this.decreaseOffset = "";
      this.increaseOffset = "";
    },
    // 设置多个
    setAllParams(value) {
      const tempLs = value.map((id) =>
        this.tempList.find((temp) => temp.order_number == id)
      );
      this.sets(tempLs);
    },
    // 设置单个
    setParams(value) {
      for (var i = 0; i < this.tempList.length; i++) {
        if (value[0] === this.tempList[i].order_number) {
          this.temp = this.handleTemperature(
            this.tempList[i].target_temperature,
            this.unit
          );

          this.apply = this.tempList[i].power !== 0;
          const target = this.groupList.find((group) =>
            group.numbers.includes(this.tempList[i].order_number)
          );
          this.groupName = target ? target?.name ?? "--" : "--";
          this.offsetTemp = "";
          this.decreaseOffset = "";
          this.increaseOffset = "";
        }
      }
    },
    handleSwitch() {
      if (!this.repeatSwitch) {
        return;
      }
      this.repeatSwitch = false;
      setTimeout(() => {
        this.repeatSwitch = true;
      }, 2000);
      if (this.isCheckIng) {
        this.$message({
          showClose: true,
          message: this.$t("Tip.isCheck"),
          type: "warning",
          duration: 1000,
        });
      }
      if (this.idList.length === 0) {
        this.$message({
          showClose: true,
          message: this.$t("AddMachine.selectEquipment"),
          type: "warning",
          duration: 1000,
        });
      }
    },
    SetSwitch() {
      const params = {};
      const apply = this.apply;
      // const ary = [];
      params.serial_number = this.$route.query.id;
      // 筛选出当前选中的数组
      const curSelectedAry = this.idList.map((id) =>
        this.tempList.find((temp) => temp.order_number == id)
      );
      params.numbers = curSelectedAry.map((selected) => {
        const { group_number, order_number } = selected;
        return {
          group_number,
          order_number,
        };
      });
      const offsetConfig = {
        incr: "increaseOffset",
        decr: "decreaseOffset",
      };
      let tempOffsetTemp = this[offsetConfig[this.offsetType]];
      this.offsetType == "incr"
        ? (tempOffsetTemp = +tempOffsetTemp)
        : (tempOffsetTemp = -tempOffsetTemp);
      //温度没有值或者根本没变
      if (isNaN(this.temp)) {
        params.target_temperature = "";
      } else {
        // 温度为 --
        if (this.tempChanged) {
          if (!this.offsetTempChanged || isNaN(tempOffsetTemp)) {
            params.target_temperature = this.temp;
          } else {
            params.target_temperature =
              Number(this.temp) + Number(tempOffsetTemp);
          }
        } else {
          if (!this.offsetTempChanged || isNaN(tempOffsetTemp)) {
            params.target_temperature = "";
          } else {
            params.target_temperature =
              Number(this.temp) + Number(tempOffsetTemp);
          }
        }
      }

      // 温度没有值
      // params.target_temperature = curTemp + curOffsetTemp;
      // !this.tempChanged || isNaN(this.temp) ? "" : parseInt(this.temp);
      params.switch = this.apply ? "open" : "close";
      const token = localStorage.getItem("token");
      const lang = localStorage.getItem("language");
      setTemperature(params, token, lang).then((response) => {
        this.tempChanged = false;
        this.offsetTempChanged = false;
        if (response.code === 200) {
          this.$message({
            showClose: true,
            message: this.$t("Tip.EditSuccess"),
            type: "success",
            duration: 1000,
          });
          // 修改温度
          // 如果传的温度值 是数字 证明 修改过了  那么就要更新最新的温度
          if (!isEmpty(params.target_temperature)) {
            this.temp = params.target_temperature;
          }
          if (this.offsetType == "incr") {
            this.decreaseOffset = "";
          } else {
            this.increaseOffset = "";
          }
          // 清除上一次修改的偏移量
          const websocPars = {
            channel: "detail",
            data: {
              serial_number: this.$route.query.id,
              refresh: 0,
            },
          };

          this.websocketsend(JSON.stringify(websocPars));
        } else {
          if (!this.isCheck) {
            setTimeout(() => {
              this.dialogVisible = true;
            }, 1000);
          }
          this.apply = !apply;
        }
      });
    },
    changeSwitch() {
      if (this.idList.length > 0) {
        if (this.isTest) {
          if (this.apply) {
            this.apply = false;
            this.$confirm(this.$t("Tip.TempConfire"), this.$t("Tip.Tips"), {
              confirmButtonText: this.$t("Login.OK"),
              cancelButtonText: this.$t("AddMachine.Cancel"),
            })
              .then(() => {
                this.apply = true;
                this.isTest = false;
                this.SetSwitch();
              })
              .catch(() => {
                this.apply = false;
              });
          } else {
            this.SetSwitch();
          }
        } else {
          this.SetSwitch();
        }
      }
    },
    confire() {
      if (this.isCheckIng) {
        if (!this.repeatCheck) {
          return;
        }
        this.repeatCheck = false;
        setTimeout(() => {
          this.repeatCheck = true;
        }, 2000);
        this.$message({
          showClose: true,
          message: this.$t("Tip.isCheck"),
          type: "warning",
          duration: 1000,
        });
      }
      if (this.idList.length > 0) {
        if (!this.repeatCheck) {
          return;
        }
        this.repeatCheck = false;
        setTimeout(() => {
          this.repeatCheck = true;
        }, 2000);
        if (!this.isCheck) {
          setTimeout(() => {
            this.dialogVisible = true;
          }, 1000);
        }

        const params = {};
        const ary = [];
        params.serial_number = this.$route.query.id;
        for (var i = 0; i < this.tempList.length; i++) {
          for (var j = 0; j < this.idList.length; j++) {
            if (this.idList[j] === this.tempList[i].order_number) {
              const number = {
                group_number: "",
                order_number: "",
              };
              number.group_number = this.tempList[i].group_number;
              number.order_number = this.tempList[i].order_number;
              ary.push(number);
            }
          }
        }
        params.numbers = ary;
        params.target_temperature = parseInt(this.temp);
        params.unit = this.unit;
        params.switch = "hold";
        const token = localStorage.getItem("token");
        const lang = localStorage.getItem("language");
        if (parseInt(this.unit) === 0) {
          if (this.temp > 500) {
            this.$message({
              showClose: true,
              message: this.$t("Tip.overShe"),
              type: "warning",
              duration: 1000,
            });
            return;
          }
          // 温度不能小于40
          if (this.temp < 40) {
            this.$message({
              showClose: true,
              message: `${this.$t("Tip.tempLessThan")} 40${
                this.unit == 0 ? "℃" : "℉"
              }`,
              type: "warning",
              duration: 1000,
            });
            return;
          }
        }
        if (this.unit === 1) {
          if (this.temp >= 932) {
            this.$message({
              showClose: true,
              message: this.$t("Tip.overHua"),
              type: "warning",
              duration: 1000,
            });
            return;
          }
          if (this.temp < 104) {
            this.$message({
              showClose: true,
              message: `${this.$t("Tip.tempLessThan")} 104${
                this.unit == 0 ? "℃" : "℉"
              }`,
              type: "warning",
              duration: 1000,
            });
            return;
          }
        }
        setTemperature(params, token, lang).then((res) => {
          this.tempChanged = false;
          if (res.code === 200) {
            this.$message({
              showClose: true,
              message: this.$t("Tip.EditSuccess"),
              type: "success",
              duration: 1000,
            });
            const params = {
              channel: "detail",
              data: {
                serial_number: this.$route.query.id,
                refresh: 0,
              },
            };

            this.websocketsend(JSON.stringify(params));
          }
        });
      } else {
        if (!this.repeatTemp) {
          return;
        }
        this.repeatTemp = false;
        setTimeout(() => {
          this.repeatTemp = true;
        }, 2000);
        this.$message({
          showClose: true,
          message: this.$t("AddMachine.selectEquipment"),
          type: "warning",
          duration: 1000,
        });
      }
    },
    setAry(ary) {
      const result1 = ary.some(function (computer) {
        return computer.temperature === "0.0";
      });
      this.isTest = !!result1;
    },
    initWebSocket() {
      // 初始化weosocket
      const token = localStorage.getItem("token");
      const lang = localStorage.getItem("language");
      const wsuri =
        "wss://api.yudotmc.com/rtk?token=" + token + "&language=" + lang;
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
      this.websock.onsend = this.websocketsend;
    },
    websocketonopen() {
      // 连接建立之后执行send方法发送数据
      const params = {
        channel: "detail",
        data: {
          serial_number: this.$route.query.id,
        },
      };
      this.websocketsend(JSON.stringify(params));
    },
    websocketonerror() {
      // 连接建立失败重连
    },
    websocketonmessage(e) {
      // 数据接收
      const redata = JSON.parse(e.data);
      if (redata.code === 10074) {
        this.$message({
          showClose: true,
          message: this.$t("Tip.NetCorrect"),
          type: "warning",
          duration: 1000,
        });
        this.$router.push({ path: "/Machine" });
      }
      if (redata.data.data.mold_check) {
        this.isCheckIng = true;
        if (!localStorage.getItem("serial")) {
          localStorage.setItem("serial", this.$route.query.serial_number);
        }

        if (localStorage.getItem("Temp").length > 0) {
          if (
            localStorage.getItem("serial") &&
            localStorage.getItem("serial") === this.$route.query.serial_number
          ) {
            this.tempList = JSON.parse(localStorage.getItem("Temp"));
          } else {
            this.checkTable = true;
            this.noDataTable = false;
            this.isHidden = false;
            localStorage.setItem("Timer", "");
            localStorage.setItem("Spm", "");
          }
        } else {
          this.checkTable = true;
          this.noDataTable = false;
          this.isHidden = false;
          localStorage.setItem("Timer", "");
          localStorage.setItem("Spm", "");
        }
        return;
      }
      if (redata.code === 0) {
        this.tempList = [];
        this.noDataTable = true;
        this.isHidden = false;
        this.checkTable = false;
      } else {
        if (
          !redata.data.data.mold_check &&
          redata.data.data.temperature_control
        ) {
          if (this.tempList.length > 0) {
            this.checkTable = false;
          }
          this.isCheckIng = false;
          this.noDataTable = false;
          this.tempList = this.handleZoneData(
            {
              data: redata.data.data.temperature_control,
            },
            redata.data.data.standard_tmc_version
          );

          if (this.unit != redata.data.data.unit) {
            // 说明单位变了
            // 需要更新右侧的 温度
            // 先转成摄氏度再进行转换
            // 如果当前有选中并且温度为有效值
            if (
              !isEmpty(this.temp) &&
              !isNaN(this.temp) &&
              this.idList.length
            ) {
              const targetArr = this.idList.map((id) =>
                this.tempList.find((temp) => temp.order_number == id)
              );
              this.temp = this.handleTemperature(
                targetArr[0].target_temperature,
                redata.data.data.unit
              );
            }
          }
          this.unit = redata.data.data.unit;
          const ary = [];
          if (this.idList.length > 0) {
            for (var i = 0; i < this.tempList.length; i++) {
              for (var j = 0; j < this.idList.length; j++) {
                if (this.tempList[i].order_number === this.idList[j]) {
                  ary.push(this.tempList[i]);
                }
              }
            }
            this.setAry(ary);
          }
          if (!localStorage.getItem("serial")) {
            localStorage.setItem(
              "Temp",
              redata.data.data.temperature_control
                ? JSON.stringify(redata.data.data.temperature_control)
                : []
            );
          }

          if (
            localStorage.getItem("serial") &&
            localStorage.getItem("serial") === this.$route.query.serial_number
          ) {
            localStorage.setItem(
              "Temp",
              redata.data.data.temperature_control
                ? JSON.stringify(redata.data.data.temperature_control)
                : []
            );
          }

          localStorage.setItem(
            "Timer",
            redata.data.data.sequencer
              ? JSON.stringify(redata.data.data.sequencer)
              : []
          );
          localStorage.setItem(
            "Spm",
            redata.data.data.shifter
              ? JSON.stringify(redata.data.data.shifter)
              : []
          );
        }
      }
    },
    websocketsend(Data) {
      // 数据发送
      this.websock.send(Data);
    },
    websocketclose(e) {
      // 关闭
    },
    // 持续移动
    moveStart(index, item) {
      if (!this.control) {
        return;
      }
      clearTimeout(this.timer); // 再次清空定时器，防止重复注册定时器
      this.isGif = true;
      this.is = index;
      this.GIF = GIF + "?+" + Math.random();
      //   this.timer && this.moveStop()
      this.timer = setTimeout(() => {
        // this.i = index
        this.isGif = false;
        this.startPoint = index;
        if (this.idList.length === 0 || this.idList.length === 1) {
          this.idList = [];
          // this.startPoint = item.order_number;
        } else {
          this.idList = [];
          // this.startPoint = item.order_number;
          this.endPoint = "";
        }
        this.idList.push(item.order_number);
      }, 1000);
    },
    // 停止移动
    moveStop() {
      this.isGif = false;
      this.is = "";
      if (this.idList.length === 0 || this.idList.length === 1) {
        this.endPoint = "";
      }

      clearInterval(this.timer);
    },

    handleConfirm() {
      if (!this.repeatCode) {
        return;
      }
      this.repeatCode = false;
      setTimeout(() => {
        this.repeatCode = true;
      }, 2000);
      if (!this.workShop.machineCode) {
        this.$message({
          showClose: true,
          message: this.$t("Temp.matchingCode"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      const params = {};
      params.serial_number = this.$route.query.id;
      params.matching_code = this.workShop.machineCode;
      const token = localStorage.getItem("token");
      const lang = localStorage.getItem("language");
      matching(params, token, lang).then((res) => {
        if (res.code === 200) {
          this.isCheck = true;
          this.dialogVisible = false;
        }
      });
    },

    selectItem(item, idx) {
      if (!this.control) {
        if (!this.repeatPower) {
          return;
        }
        this.repeatPower = false;
        setTimeout(() => {
          this.repeatPower = true;
        }, 2000);
        this.$message({
          showClose: true,
          message: this.$t("AddMachine.NoPower"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      this.tempChanged = false;
      this.offsetTempChanged = false;

      if (!isEmpty(this.startPoint) && isEmpty(this.endPoint)) {
        this.idList = [];
        // this.endPoint = item.order_number;
        this.endPoint = idx;
        // if (parseInt(this.endPoint) > parseInt(this.startPoint)) {
        //   for (var i = 0; i < this.tempList.length; i++) {
        //     if (
        //       parseInt(this.endPoint) >=
        //         parseInt(this.tempList[i].order_number) &&
        //       parseInt(this.startPoint) <=
        //         parseInt(this.tempList[i].order_number)
        //     ) {
        //       this.idList.push(this.tempList[i].order_number);
        //     }
        //   }
        // } else {
        //   // eslint-disable-next-line no-redeclare
        //   for (var i = 0; i < this.tempList.length; i++) {
        //     if (
        //       parseInt(this.endPoint) <=
        //         parseInt(this.tempList[i].order_number) &&
        //       parseInt(this.startPoint) >=
        //         parseInt(this.tempList[i].order_number)
        //     ) {
        //       this.idList.push(this.tempList[i].order_number);
        //     }
        //   }
        // }
        let startPos, endPos;
        if (parseInt(this.endPoint) > parseInt(this.startPoint)) {
          startPos = this.startPoint;
          endPos = this.endPoint;
        } else {
          startPos = this.endPoint;
          endPos = this.startPoint;
        }
        let targetArr = this.tempList.slice(startPos, endPos + 1);
        this.idList = targetArr.map((target) => target.order_number);
      } else if (!isEmpty(this.startPoint) && !isEmpty(this.endPoint)) {
        if (this.idList.includes(item.order_number)) {
          this.idList.splice(
            this.idList.findIndex((it) => it === item.order_number),
            1
          );
        } else {
          this.idList.push(item.order_number);
        }
      } else {
        this.idList = [];
        this.idList.push(item.order_number);
      }
    },
    changeHidden() {
      if (!this.control) {
        if (!this.repeatControl) {
          return;
        }
        this.repeatControl = false;
        setTimeout(() => {
          this.repeatControl = true;
        }, 2000);
        this.$message({
          showClose: true,
          message: this.$t("AddMachine.NoPower"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      this.isHidden = !this.isHidden;
    },
    validate() {
      const params = {};
      params.serial_number = this.$route.query.serial_number;
      const token = localStorage.getItem("token");
      const lang = localStorage.getItem("language");
      validate(params, token, lang).then((res) => {
        this.isCheck = res.data.is_matching !== 0;
        this.control = res.data.control_permission === 1;
        this.isHidden = res.data.control_permission === 1;
        if (!this.control) {
          this.$emit("AllHidden");
        }
      });
    },
    // 获取分组列表
    // sync 0不同步 1 同步
    async getGroupListFunc(sync = 1) {
      const token = localStorage.getItem("token");
      await getGroupList(
        {
          serial_number: this.$route.query.id,
          sync,
        },
        token
      ).then((res) => {
        this.groupList = res?.data ?? [];
      });
    },
    //添加到分组
    async handleAddGroup() {
      if (this.idList?.length <= 1) {
        showMessage(this.$t("Update2.selectZone"));
        return;
      }
      if (this.buttonDisable) {
        showMessage(this.$t("Update2.noRepeat"));
        return;
      }
      if (this.groupName == "--" || this.groupName == "") {
        showMessage(this.$t("Update2.inputName"));
        return;
      }
      const token = localStorage.getItem("token");
      editGroupList(
        {
          action: "add",
          numbers: this.idList,
          group_name: this.groupName,
          serial_number: this.$route.query.id,
        },
        token
      )
        .then(async () => {
          showMessage(this.$t("Update2.addGroupSuccess"), "success");
          this.buttonStatus = false;
          await this.getGroupListFunc(2);
          // 设置高亮
          this.$refs.groupListRef.setActiveArr();
          // 设置 显示编辑组
          // 将组名滚动到视图内
          // 新增的肯定是最后一个
          const targetElement = [
            ...document.getElementsByClassName(`list-wrapper`),
          ].at(-1);
          if (!isInViewPort(targetElement)) {
            targetElement?.scrollIntoView();
          }
          //
        })
        .catch((err) => {
          // 调新增接口失败
          // this.groupName = this.preGroupName;
        })
        .finally((res) => {
          // 刷新 组的借口
        });
    },
    // 修改组
    handleEditGroup() {
      if (this.groupName == "--" || this.groupName == "") {
        showMessage(this.$t("Update2.inputName"));
        return;
      }
      this.preGroupName = this.groupName;
      const token = localStorage.getItem("token");
      const targetGroup = this.groupList.find(
        (item) => item.numbers.join("") == this.idList.join("")
      );
      editGroupList(
        {
          action: "edit",
          numbers: this.idList,
          group_name: this.groupName,
          serial_number: this.$route.query.id,
          group_id: targetGroup.id,
        },
        token
      )
        .then(() => {
          showMessage(this.$t("Tip.EditSuccess"), "success");
          this.getGroupListFunc(2);
        })
        .catch((err) => {
          // 调新增接口失败
          this.groupName = this.preGroupName;
        });
    },
    // 分组内容选中
    handleSelectGroup(selectGroup) {
      if (!selectGroup.length) {
        // 如果没有选中 则 取消选中所有的  直接执行取消执行按钮绑定的方法
        this.deselect();
        this.isHidden = false;
        return;
      }
      this.idList = [];
      this.startPoint = null;
      this.endPoint = null;
      this.isHidden = true;
      // 控制右侧面板
      // this.Cancel('cancel')
      selectGroup.forEach((group) => {
        // 获取当前组里面的 模块id
        const groupIdsArr = [...group.numbers];

        // 根据 groupIdsArr 找到tempList中对应的数据
        const selectArr = groupIdsArr.map((id) => {
          const target = this.tempList.find(
            (listItem) => listItem.order_number == id
          );
          return target;
        });
        this.idList.push(...groupIdsArr);
      });
      // focus
      this.$nextTick(() => {
        if (this.isSameGroup) {
          this.$refs.groupNameRef.focus();
        } else {
          this.$refs.groupNameRef.blur();
        }
      });
      if (!this.startPoint && !this.endPoint) {
        // 这样做不准确 只是为了取消选中状态
        this.startPoint = this.idList[0];
        this.endPoint = this.idList.at(-1);
      }
      // 判断选中的点位中的第一个元素是否在可视区域范围内
      // 如果不在 就手动控制其进入视野
      const firstTargetIdx = this.tempList.findIndex(
        (temp) => temp.order_number == this.idList[0]
      );
      const targetElement =
        document.querySelectorAll(".card-panel-col")[firstTargetIdx];
      if (!isInViewPort(targetElement)) {
        targetElement.scrollIntoView();
      }
    },
    handleGroupNameFocus() {
      if (this.idList.length < 1) {
        showMessage(this.$t("Update2.selectZone"));
        this.$refs.groupNameRef.blur();
        return;
      }
      // 判断选中的是否都是一个组的
      // 判断是否都是新数据
      const isAllNewData = this.idList.every((item) => {
        const targetIdx = this.groupList.findIndex((group) =>
          group.numbers.includes(item)
        );
        return targetIdx < 0;
      });
      if (!this.isSameGroup && !isAllNewData) {
        showMessage(this.$t("Update2.noRepeat"));
        this.$refs.groupNameRef.blur();
      } else {
        if (this.groupName == "--") {
          this.groupName = "";
        }
      }
    },
    // 删除分组
    handleremoveGroup({ id, numbers, name }) {
      // 确认删除
      // 删除的接口很慢
      const token = localStorage.getItem("token");
      editGroupList(
        {
          action: "del",
          group_name: name,
          numbers: numbers,
          serial_number: this.$route.query.id,
        },
        token
      ).then(() => {
        // 提示删除成功
        showMessage(this.$t("Setting.RemovedSuccessfully"), "success");
        // 刷新组的 接口
        this.getGroupListFunc(2);
        // 如果删除的是当前选中的   那么要清除页面的选中
        numbers.forEach((id) => {
          this.idList.splice(
            this.idList.findIndex((it) => it === id),
            1
          );
        });
        if (!this.idList.length) {
          this.startPoint = "";
          this.endPoint = "";
        }
      });
    },
    handleOffsetTempChange(action) {
      this.offsetType = action;
      this.offsetTempChanged = true;
    },
    handleSetOffsetTemp(action) {
      // 如果上一个偏移量还在处理中， 那么就不处理这一个
      if (this.offsetLoading) return;
      if (!this.idList.length) {
        showMessage(this.$t("Update2.selectZone"));
        return;
      }
      this.offsetType = action;
      const preTempValue = this.offsetTemp;
      const offsetConfig = {
        incr: "increaseOffset",
        decr: "decreaseOffset",
      };
      this.offsetTemp = this[offsetConfig[action]];
      // 校验 选中数组的温度
      // 筛选中选中的数组
      const targetArr = this.idList.map((id) =>
        this.tempList.find((temp) => temp.order_number == id)
      );
      let caozuoArr;
      if (action == "incr") {
        caozuoArr = targetArr.map((target) => {
          return {
            order_number: target.order_number,
            group_number: target.group_number,
            temperature:
              this.handleTemperature(target.target_temperature, this.unit) +
              Number(this.offsetTemp),
          };
        });
        if (this.unit == 0) {
          if (caozuoArr.find((item) => item.temperature > 500)) {
            showMessage(this.$t("Tip.overShe"));
            return;
          }
        } else {
          if (caozuoArr.find((item) => item.temperature >= 932)) {
            showMessage(this.$t("Tip.overHua"));
            return;
          }
        }
      } else {
        caozuoArr = targetArr.map((target) => {
          return {
            order_number: target.order_number,
            group_number: target.group_number,
            temperature:
              this.handleTemperature(target.target_temperature, this.unit) -
              Number(this.offsetTemp),
          };
        });
        if (this.unit == 0) {
          if (caozuoArr.find((item) => item.temperature < 40)) {
            showMessage(`${this.$t("Tip.tempLessThan")} 40℃`);
            return;
          }
        } else {
          if (caozuoArr.find((item) => item.temperature < 104)) {
            showMessage(`${this.$t("Tip.tempLessThan")} 104℉`);
            return;
          }
        }
      }
      const token = localStorage.getItem("token");
      this.offsetLoading = true;
      setOffsetTemp(
        {
          serial_number: this.$route.query.id,
          numbers: caozuoArr,
          action,
          temperature: Number(this.offsetTemp),
          // unit: this.unit,
        },
        token
      )
        .then((res) => {
          if (res.code === 200) {
            this.offsetTempChanged = false;
            showMessage(this.$t("Tip.EditSuccess"), "success");
            // 如果此时温度输入框里有温度 那么就要更新最新的温度
            if (!isEmpty(this.temp)) {
              const tempOffsetTemp =
                action == "incr" ? +this.offsetTemp : -this.offsetTemp;
              this.temp = +this.temp + tempOffsetTemp;
            }
            if (action == "incr") {
              this.decreaseOffset = "";
            } else {
              this.increaseOffset = "";
            }
          } else {
            if (!this.isCheck) {
              setTimeout(() => {
                this.dialogVisible = true;
              }, 1000);
            }
            this.apply = !apply;
          }
        })
        .finally(() => {
          this.offsetLoading = false;
        });
    },
    handleSwitchChange() {},
    handleChangeSwitch() {
      this.groupSettingSwitch = !this.groupSettingSwitch;
    },
    // deselect () {
    //   this.idList = []
    // }
  },
  created() {
    this.initWebSocket();
    this.validate();
    this.getGroupListFunc();
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.checkBox {
  position: absolute;
  right: 80px;
  top: -45px;

  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #4478f3;
    border-color: #4478f3;
  }

  /deep/ .el-checkbox__label {
    color: #7e7d7d;
    font-size: 16px;
    line-height: 25px;
  }

  /deep/ .el-checkbox__inner {
    width: 24px;
    height: 24px;
    padding-top: 20px;
    font-weight: bold;
  }

  /deep/ .el-checkbox__inner::after {
    height: 12px;
    left: 7px;
    position: absolute;
    top: 3px;
    width: 6px;
  }
}

.tips {
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: #8e8c8c;
  padding-bottom: 20px;
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;

  position: absolute;
  flex-direction: column;
  left: 0;
  top: 100px;

  .empty_text {
    line-height: 20px !important;
    font-weight: bold;
    font-size: 16px;
    color: #909399;
  }
}

@media (max-width: 1367px) {
  .temp .temp_icon {
    top: 325px !important;
  }
}

@media (min-width: 1367px) and (max-width: 1919px) {
  .temp .temp_icon {
    top: 365px !important;
  }
}

.dialog /deep/ .el-dialog {
  border-radius: 20px !important;
  background: #e7e9ee;
  margin-top: 30vh !important;
  filter: drop-shadow(0 6px 6px rgba(0, 0, 0, 0.16));
}

.dialog /deep/ .el-dialog__body {
  padding: 0px 20px 10px 20px;
}

.btn-normal {
  width: 70px;
  height: 32px;
  border-radius: 20px;
  font-size: 13px;
  line-height: 34px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  float: right;
  margin-left: 10px;
  cursor: pointer;
}

.dialog /deep/ .el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
}

.dialog /deep/ .plan-form.r3 .label {
  font-size: 16px;
}

.dialog /deep/ .el-input--suffix {
  width: 100% !important;
  padding-right: 15px;
}

.dialog /deep/ .plan-form .row .form-item {
  width: 33.4% !important;
}

.dialog /deep/ .el-dialog__title {
  font-weight: bold;
  font-size: 20px;
}

.dialog /deep/ .el-dialog__body {
  padding: 20px 20px 0 20px !important;
}

.dialog /deep/ .el-dialog__header {
  padding: 20px 20px 10px !important;
  text-align: left;
}

.dialog /deep/ .el-dialog__headerbtn {
  font-size: 30px !important;
  top: 12px;
}

.dialog /deep/ .el-dialog__footer {
  padding: 0px 20px 15px !important;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.form-box {
  .label {
    font-size: 18px;
    font-weight: 400;
    color: #4d4f5c;
    text-align: left;
  }
}

.temp_list::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 2px;
  height: 2px;
  background-color: #fff;
  cursor: pointer;
}

/*定义滚动条轨道 内阴影+圆角*/
.temp_list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px rgba(255, 255, 255, 0.5);
  border-radius: 0px;
  background-color: #fff;
}

/*定义滑块 内阴影+圆角*/
.temp_list::-webkit-scrollbar-thumb {
  border-radius: 0px;
  box-shadow: inset 0 0 0px rgba(255, 255, 255, 0.5);
  background-color: #707070;
}

.temp {
  .confirmActive {
    background: url(../../../../assets/image/rightConfire.png) no-repeat;
    background-size: cover;
    transition: all 0.3s;
  }

  .confirmActive:active {
    opacity: 0.8;
  }

  .confirmActive:hover {
    background: url(../../../../assets/image/confirmHover.png);
    background-size: cover;
  }

  height: calc(100vh - 126px);
  position: relative;

  .temp_control {
    float: left;
    width: 200px;
    position: absolute;
    right: 0;
    top: 0;
    background: #a8abb5;
    box-shadow: -1px 0px 0px 0px #9497a2;
    height: 100%;

    .temp_content {
      width: 100%;
      height: 100%;

      .temp_apply {
        display: flex;
        justify-content: space-between;
        padding: 15px 10px 10px 10px;
        text-align: left;
        color: #4b4c50;
        font-size: 14px;

        /deep/ .el-switch {
          height: 22px !important;
          margin-left: 5px;
        }

        /deep/ .el-switch__core {
          width: 50px !important;
          height: 22px !important;
          background: #62646b !important;
          border: none !important;
          border-radius: 22px;
        }

        /deep/ .el-switch.is-checked .el-switch__core {
          background-color: #4478f3 !important;
        }

        /deep/ .el-switch__core:after {
          content: "";
          position: absolute;
          top: 0px;
          left: 0px;
          transition: all 0.3s;
          width: 22px;
          height: 22px;
          background: #dedfdf;
          border-radius: 22px;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
        }

        /deep/ .el-switch.is-checked .el-switch__core::after {
          top: 0px;
          left: 28px !important;
          margin-left: 0px !important;
          background: #fff !important;
          z-index: 200;
        }
      }

      .group_setting_wrapper {
        padding: 0 10px 20px 10px;

        .group_setting {
          color: #cbd1e2;
          background: #62646b;
          border: 1px solid #62646b;
          border-radius: 4px;

          .group_setting_name {
            font-size: 14px;
            text-align: left;
            padding: 8px 0 0 8px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.14);

            .group_name_title {
              padding: 0;
              margin: 0;
              padding-bottom: 5px;
            }

            .group_name_input {
              /deep/ .el-input__inner {
                width: 100%;
                height: 100%;
                float: left;
                background: #62646b;
                border: 1px solid #62646b;
                text-align: left;
                color: #cbd1e2;
                font-size: 14px;
                cursor: pointer;
                font-size: 14px;
                padding: 0 0 0 0px;
              }
            }
          }

          .group_button_wrapper {
            width: 100%;
            padding: 0px 5px 10px 5px;

            .groupbutton {
              font-size: 12px;
              min-width: 80px;
              text-align: center;
              line-height: 26px;
              height: 26px;
              color: #fff;
              border-radius: 20px;
              cursor: pointer;
              margin-top: 10px;
            }

            .button_primary {
              background: #4478f3;
            }

            .button_primary:active {
              opacity: 0.8;
            }

            .button_info {
              background: #a8abb5;
            }

            .button_info:active {
              opacity: 0.8;
            }
          }
        }
      }

      .setting_wrapper {
        padding: 0 10px;
        height: 120px;

        .content_offset_val {
          // line-height: 52px;
          position: relative;
          height: 42px;
          line-height: 42px;
          text-indent: 55px;
          width: 100%;
          background: #858890;
        }

        .content_offset_val::before {
          content: "";
          width: 14px;
          height: 2px;
          background: #75767c;
          left: 10px;
          top: 16px;
          position: absolute;
        }

        .content_offset_plus::after {
          content: "";
          width: 14px;
          height: 2px;
          background: #75767c;
          left: 10px;
          top: 16px;
          position: absolute;
          rotate: (90deg);
        }

        .value_title {
          font-size: 14px;
          text-align: left;
          color: #4b4c50;
        }

        .confires {
          height: 34px;
          line-height: 34px;
          margin-top: 7px;
          float: left;
          width: 40px;
          background: #62646b;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .confires_confirmed {
          cursor: pointer;
        }

        .confires_loading {
          cursor: not-allowed;
        }

        .value_result {
          height: 34px;
          line-height: 34px;
          margin-top: 7px;
          float: left;
          width: 140px;

          /deep/ .el-input__inner {
            width: 100%;
            height: 100%;
            float: left;
            background: #62646b;
            border: 1px solid #62646b;
            text-align: left;
            color: #cbd1e2;
            font-size: 14px;
            cursor: pointer;
            font-size: 14px;
            padding: 0 0 0 36px;
          }
        }

        .value_top {
          border-radius: 4px 0 0 0;

          /deep/ .el-input__inner {
            border-radius: 4px 0 0 0px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.14);
          }
        }

        .confirm_top {
          border-radius: 0 4px 0 0;
          border-bottom: 1px solid rgba(255, 255, 255, 0.14);
        }

        .value_bottom {
          margin-top: 0;
          border-radius: 0 0 0 4px;

          /deep/ .el-input__inner {
            border-radius: 0 0 0 4px;
          }

          //
        }

        .confirm_bottom {
          margin-top: 0;
          border-radius: 0 0 4px 0;
        }
      }

      .temp_value {
        padding: 0 10px;
        height: 70px;

        .value_title {
          font-size: 14px;
          text-align: left;
          color: #4b4c50;
        }

        .confires {
          height: 34px;
          line-height: 34px;
          border-radius: 0 4px 4px 0;
          margin-top: 7px;
          float: left;
          width: 40px;
          background: #62646b;
          display: flex;
          justify-content: center;
          cursor: pointer;
          align-items: center;
        }

        .value_result {
          height: 34px;
          line-height: 34px;
          border-radius: 4px 0 0 4px;
          margin-top: 7px;
          float: left;
          width: 140px;
          position: relative;

          /deep/ .el-input__inner {
            width: 100%;
            height: 100%;
            float: left;
            background: #62646b;
            border: 1px solid #62646b;
            text-align: left;
            color: #cbd1e2;
            font-size: 14px;
            cursor: pointer;
            border-radius: 4px 0 0 4px;
            font-size: 14px;
          }
        }
      }
    }
  }

  .temp_params {
    background: #a8abb5;
    box-shadow: -1px 0px 0px 0px #9497a2;
    width: 45px;
    color: #6b6e78;
    height: 100%;
    word-wrap: break-word;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: tb-rl;
    padding: 25px;
    display: flex;
    -webkit-display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    position: absolute;
    right: 0;
    text-align: left;
  }

  .temp_icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 425px;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
  }

  .temp_list {
    float: left;
    height: calc(100vh - 101px);
    padding: 25px 5px 30px 20px;
    overflow: auto;

    .temp_item {
      margin-right: 20px;
      height: 140px;
      border-radius: 6px;
      box-sizing: border-box;
      background: rgba(255, 255, 255, 0.64);
      box-shadow: 0px 6px 6px 0px rgba(161, 162, 160, 0.63);
      position: relative;
      cursor: pointer;

      .temp_top {
        background-color: #e39629;
        height: 20px;
        line-height: 20px;
        font-size: 13px;
        color: #fef5e8;
        border-radius: 4px 4px 0 0;
        padding-right: 10px;
        display: flex;

        .temp_top_title {
          width: 50%;
        }

        .temp_top_text {
          width: 50%;
          text-align: right;
          font-size: 13px;
        }
      }

      .temp_con {
        width: 100%;
        padding: 0 10px;
        height: 120px;
        border-radius: 0 0 6px 6px;
        position: relative;

        .item_img {
          width: 100%;
          height: 100%;
          left: 0;
          top: 20px;
          position: absolute;
          display: flex;
          justify-content: center;

          img {
            width: 80px;
            height: 80px;
            z-index: 9999;
          }
        }

        .temp_jk {
          position: absolute;
          right: 10px;
          bottom: 35px;
          width: 16px;
          height: 16px;
          border-radius: 16px;
          font-size: 12px;
          text-align: center;
          line-height: 16px;
          color: #656567;
          background: #afb0b2;
        }

        .temp_pv {
          padding: 10px 0 4px 0;
          font-size: 26px;
          font-weight: 700;
          text-align: left;
          color: #473f3b;
        }

        .temp_No {
          width: 80%;
          // margin-left: 10%;
          right: 10px;
          text-align: right;
          position: absolute;
          top: 5px;
        }

        .temp_target {
          font-size: 12px;
          text-align: left;
          color: #473f3b;
        }

        .temp_sv {
          font-size: 18px;
          font-weight: 700;
          text-align: left;
          color: #473f3b;
          padding: 0px 0px 8px 0;
        }

        .temp_ele {
          font-size: 12px;
          text-align: left;
          color: #473f3b;

          .temp_per,
          .temp_current {
            width: 50%;
            float: left;
            text-indent: 2px;
          }
        }
      }
    }
  }
}
</style>
