<template>
  <!-- 分组内容 -->
  <!-- 绑定拖拽事件 -->
  <div
    class="group_content_wrapper"
    :class="[foldSwitch ? '' : 'wrapper_close', setWraperrPosition]"
    ref="groupRef"
    :draggable="foldSwitch ? false : true"
  >
    <!-- 拖拽移动 参考api https://www.cnblogs.com/dcyd/p/16543889.html -->
    <!--     @dragstart="handleTouchStart"
    @drag="handleTouchMove"
    @dragend="handleTouchEnd" -->
    <!--     @mousedown="handleTouchStart"
    @mousemove="handleTouchMove"
    @mouseup="handleTouchEnd" -->
    <!-- 标题 -->

    <div class="group_content_title" :class="foldSwitch ? '' : 'title_close'">
      <!--@click="changePosition"  -->
      {{ $t("Update2.group") }}
    </div>
    <!-- 折叠区-->
    <div
      class="group_content_fold"
      v-show="foldSwitch"
      :class="isSetting ? 'fold_close' : expandClass"
      :style="customStyle"
    >
      <div class="group_content_select">
        <!-- 互斥 -->
        <!-- All  全选  点击之后  选中所有的组-->
        <!-- Multiple 多选   -->
        <el-checkbox
          v-model="checked"
          :true-label="1"
          :false-label="2"
          @change="handleChangeGroupWay"
          >{{ $t("Update2.allSelect") }}</el-checkbox
        >
        <el-checkbox
          v-model="checked"
          :true-label="3"
          :false-label="4"
          @change="handleChangeGroupWay"
          >{{ $t("Update2.mulSelect") }}</el-checkbox
        >
      </div>
      <div class="group_content_list">
        <template v-for="item in groupList">
          <div class="list-wrapper" :key="item.name">
            <div
              class="list-content"
              @click="handleSelectGroup(item)"
              :class="
                activeGroupArr.includes(item.name) ? 'list-content_active' : ''
              "
            >
              {{ item.name }}
            </div>
            <div class="list-close-wrapper">
              <div
                v-if="canDelete"
                class="close"
                :class="
                  activeGroupArr.includes(item.name) ? 'close_active' : ''
                "
                @click="handleRemoveGroup(item)"
              ></div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 折叠 -->
    <div
      class="group_fold_tool"
      :class="foldSwitch ? '' : 'group_fold_tool_collect'"
      @click="handleExpandGroup"
    ></div>
  </div>
</template>
<script>
// 功能点
// 点击 分组组件  点击高亮   删除组名
// 页面 选择  与分组组件联动 不需要
//  watch checked  切换mularr 或者allArr  来更新 activeGroupArr

// 单个不能分组
// 分组 数据不能有重复的   禁用分组按钮

// 点击选中分组
export default {
  data() {
    return {
      foldSwitch: false,
      checked: 3,
      activeGroupId: null, //当前选中的组名
      activeGroupArr: [], //当前选中的,
      isLeft: true,
      flags: false,
      position: { x: 0, y: 0 },
      nx: "",
      ny: "",
      dx: "",
      dy: "",
      xPum: "",
      yPum: "",
    };
  },

  mounted() {},
  props: {
    groupList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectedIdArr: Array, //选中的 id 数组
    type: {
      type: Number,
      default: 1,
    }, // 1. temp 2.timer
    isSetting: Boolean, //右侧设置面板 开关状态
    customStyle: Object,
    page: {
      type: String,
      default: () => {
        return "temp";
      },
    },
    expandClass: {
      type: String,
      default: "fold_expand",
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    setWraperrPosition() {
      if (this.page == "temp") {
        if (!this.isLeft) {
          if (this.isSetting) {
            return "wrapper_right_setting";
          } else {
            return "wrapper_right_temp";
          }
        } else {
          return "wrapper_left";
        }
      }
      return this.isLeft ? "wrapper_left" : "wrapper_right";
    },
  },
  watch: {
    selectedIdArr(val) {
      // 监听ids  用于判断  点击group选中之后 再次点击组内成员 取消选中后 对应选中的group 也要取消选中
      //
      this.groupList.forEach((item) => {
        // 判断当前组的id 是否都在 ids中
        const isAllSelect = item.numbers.every(
          (id) => val.includes(id) || val.includes(Number(id))
        );
        // console.log(isAllSelect,'aaaa')
        if (!isAllSelect) {
          // 如果没有都在ids  并且当前 activeGroupArr 中有当前id 就需要移除
          const targetIdx = this.activeGroupArr.findIndex(
            (actId) => actId == item.name
          );
          if (targetIdx > -1) {
            this.activeGroupArr.splice(targetIdx, 1);
          }
        }
      });
    },
    checked(val) {
      // console.log(val,'val')
      if (val != 1 && val != 3) {
        //全部取消选中
        this.$emit("handleSelectGroup", []);
      }
    },
  },
  methods: {
    // 选择组名
    // 选中 all 的情况下 如果取消选中某一个 那么同时要切换为 Multiple
    handleSelectGroup(item) {
      // 编辑后 activeGroupArr 重复了
      const curIdx = this.activeGroupArr.findIndex(
        (group) => group == item.name
      );
      // 如果全选和多选都没有选中 只能单选
      if (this.checked != 1 && this.checked != 3) {
        if (curIdx > -1) {
          // 如果有  则 删除
          this.activeGroupArr.splice(curIdx, 1);
        } else {
          this.activeGroupArr = [item.name];
        }
      } else {
        // 选中与取消选中
        // 可以选择多个 mulGroupArray
        // 如果数组里面有当前选择的 那么就移除  反之 则添加
        if (curIdx > -1) {
          // 如果有  则 删除
          this.activeGroupArr.splice(curIdx, 1);
        } else {
          this.activeGroupArr.push(item.name);
        }
      }
      const seletGroupArr = this.groupList.filter((son) =>
        this.activeGroupArr.includes(son.name)
      );
      this.$emit("handleSelectGroup", seletGroupArr);

      // 向父组件传递选中的 group的数据
    },
    // 切换分组方式
    handleChangeGroupWay(val) {
      if (val == 1) {
        this.activeGroupArr = this.groupList.map((item) => item.name);
      }
      if (val == 1 || val == 3) {
        const seletGroupArr = this.groupList.filter((son) =>
          this.activeGroupArr.includes(son.name)
        );
        // 向父组件传递选中的 group的数据
        this.$emit("handleSelectGroup", seletGroupArr);
      }
    },
    //
    setActiveArr() {
      // this.activeGroupArr
      const target = this.groupList.find(
        (item) => item.numbers.join(",") == this.selectedIdArr.join(",")
      );
      if (target) {
        if (!this.activeGroupArr.includes(target.name)) {
          // 防止重复添加
          this.activeGroupArr.push(target.name);
        }
      }
    },
    // 删除分组
    handleRemoveGroup(item) {
      this.$confirm(this.$t("Update2.confirmDelete"), this.$t("Tip.Tips"), {
        confirmButtonText: this.$t("Login.OK"),
        cancelButtonText: this.$t("AddMachine.Cancel"),
      }).then(() => {
        this.$emit("removeGroup", item);
      });
    },
    changePosition() {
      if (this.foldSwitch) return;
      this.isLeft = !this.isLeft;
    },
    handleExpandGroup() {
      this.foldSwitch = !this.foldSwitch;
      // this.isLeft = true;
    },

    // 拖拽事件
    handleTouchStart(event) {
      if (this.foldSwitch) return;
      let target = this.$refs.groupRef;
      target.style.width = 150 + "px";
      this.flags = true;
      var touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      this.maxW = document.body.clientWidth - target.offsetWidth;
      this.maxH = document.body.clientHeight - target.offsetHeight;

      this.position.x = touch.clientX - target.offsetLeft;
      this.position.y = touch.clientY - target.offsetTop;
      this.dx = touch.clientX;
      this.dy = touch.clientY;
    },
    handleTouchMove(event) {
      event.preventDefault();
      if (this.foldSwitch) return;
      let target = this.$refs.groupRef;
      if (this.flags) {
        var touch;
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        this.nx = touch.clientX - this.position.x;
        this.ny = touch.clientY - this.position.y;

        if (this.nx < 0) {
          this.nx = 0;
        } else if (this.nx > this.maxW) {
          this.nx = this.maxW;
        }

        if (this.ny < 0) {
          this.ny = 0;
        } else if (this.ny >= this.maxH) {
          this.ny = this.maxH;
        }

        target.style.left = this.nx + "px";
        // target.style.top = this.ny + "px";
      }
    },
    handleTouchEnd(event) {
      if (this.foldSwitch) return;
      let target = this.$refs.groupRef;
      if (this.isLeft) {
        if (this.nx > 100) {
          //
          this.isLeft = false;
        } else {
          this.isLeft = true;
        }
      } else {
        if (this.nx !== "" && this.nx < 1600) {
          //
          this.isLeft = true;
        } else {
          this.isLeft = false;
        }
      }
      // target.style.left = this.nx + "px";
      target.removeAttribute("style");
      this.nx = "";
      this.flags = false;
    },
  },
};
</script>
<style scoped lang="less">
// 最大宽度 1618px
.wrapper_close {
  height: 44px !important;
}
.wrapper_left {
  left: 156px;
}
.wrapper_right {
  right: 0;
}
.wrapper_right_setting {
  right: 306px;
}
.wrapper_right_temp {
  right: 55px;
}
.group_content_wrapper {
  height: 88px;
  background: rgba(72, 77, 91, 0.93);
  color: #cacaca;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  position: fixed;
  bottom: 0;
  // width: 1618px;
  display: flex;

  .group_content_title {
    font-size: 20px;
    width: 100px;
    line-height: 88px;
    text-align: center;
  }
  .title_close {
    line-height: 44px;
  }
  .group_content_select {
    .el-checkbox {
      display: flex;
    }
  }

  .fold_expand {
    width: 1560px;
    // width: 1718px;
  }
  .fold_expand_max {
    width: 1606px;
  }
  .fold_close {
    width: 1410px;
  }

  .group_content_fold {
    // flex: 1;
    height: 100%;
    display: flex;
    transition: width 0.3s;

    .group_content_select {
      min-width: 115px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      //  is-checked
      /deep/ .el-checkbox {
        color: #cacaca;
      }

      /deep/ .el-checkbox__inner {
        width: 22px;
        height: 22px;
      }
      /deep/.el-checkbox__inner::after {
        height: 10px;
        top: 1px;
        left: 6px;
        width: 5px;
        border-width: 3px;
      }
      .is-checked {
        /deep/.el-checkbox__label {
          color: #cacaca;
        }
        /deep/ .el-checkbox__inner {
          background: #4478f3;
          border-color: #4478f3;
        }
        // /deep/.el-checkbox__inner::after {
        //   height: 10px;
        //   top: 1px;
        //   left: 6px;
        //   width: 5px;
        //   border-width: 3px;
        // }
      }
    }
    // 修改 滚动条样式
    // 参考地址 https://blog.csdn.net/yolo_link/article/details/125973469
    .group_content_list::-webkit-scrollbar {
      height: 8px;
      cursor: pointer;
    }
    .group_content_list::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      // opacity: 0.2;
      background: #818796;
    }
    .group_content_list::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: transparent;
    }
    .group_content_list {
      display: flex;
      flex: 1;
      align-items: center;
      overflow: auto;
      // 修改滚动条样式

      .list-wrapper {
        line-height: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        cursor: pointer;

        // font-size: 14px;
        // .size-14();
        .list-content {
          min-width: 105px;
          border-radius: 20px;
          background: #e0e3eb;
          color: #3d3d3d;
          // text-indent: 10px;
        }

        .list-content_active {
          border: 1px solid #e2e2e2;
          background: #353e5a;
          color: #fbfbfc;
        }
      }
    }
  }

  .group_fold_tool {
    cursor: pointer;
    width: 50px;
    background: #424650;
    height: 100%;
    background-image: url("../assets/image/group_fold.png");
    background-position: center;
    // background-size: 20px 20px;
    background-repeat: no-repeat;
  }

  .group_fold_tool_collect {
    rotate: (180deg);
  }

  .list-close-wrapper {
    position: relative;
    width: 32px;
    height: 32px;
    left: 2px;
  }

  .close {
    position: absolute;
    width: 32px;
    height: 32px;
    border: 1px solid #e0e3eb;
    border-radius: 32px;
    background-color: #fff;
  }

  .close_active {
    background: #353e5a;
    border: 1px solid #e2e2e2;
  }

  .close::before,
  .close::after {
    position: absolute;
    content: " ";
    background-color: #5f5f61;
    width: 0px;
    height: 13px;
    border: 1px solid;
    left: 14px;
    top: 7px;
    z-index: 999;
    color: #3d3d3d;
  }

  .close_active::before,
  .close_active::after {
    // background-color: #fbfbfc;
    color: #0e1c44;
    // border-color: #E2E2E2;
  }

  .close::before {
    transform: rotate(45deg);
  }

  .close::after {
    transform: rotate(-45deg);
  }
}
</style>
