<template>
  <div class="moldMain">
    <div class="moldTop">
      <div class="backIcon">
        <div class="backImg" @click="toBack"></div>
      </div>
      <div class="backTitie">{{ name }}</div>
    </div>
    <el-tabs v-model="activeName" tab-position="top" type="card" @tab-click="showTip">
      <el-tab-pane :label="$t('TempTab.Temp')" name="Temp" :disabled='isDisable' ref="temp"></el-tab-pane>
      <el-tab-pane :label="$t('TempTab.Timer')" name="Timer" :disabled='isDisable'></el-tab-pane>
      <el-tab-pane :label="$t('TempTab.SPM')" name="Spm" :disabled='isDisable'></el-tab-pane>
      <el-tab-pane :label="$t('TempTab.RemoteOperationRecord')" name="Record"></el-tab-pane>
    </el-tabs>
    <component :is="componentId[activeName]" ref="tabs" @AllHidden='AllHidden' @hiddenSelect='hiddenSelect'
      @forwardTab="forward" />
    <div class="deselect" @click="deselect" v-show='isDeselect && activeName === "Temp"'
      :style="{ right: $i18n.locale === 'zh' ? '170px' : '200px' }">{{ $t('Temp.Cancel') }}</div>

  </div>
</template>

<script>
import Timer from './timer/index'
import Temp from './temp/index'
import Spm from './spm/index'
import Record from './record/index'
export default {
  name: 'MoldName',
  components: {
    Timer,
    Temp,
    Spm,
    Record
  },
  mixins: [],
  data() {
    return {
      activeName: 'Temp',
      isDisable: false,
      isAllSelect: false,
      isDeselect: false,
      isAll: false,
      name: '',
      componentId: {
        Temp: 'Temp',
        Timer: 'Timer',
        Spm: 'Spm',
        Record: 'Record'
      }
    }
  },
  mounted() {
    if (sessionStorage.getItem('currentTab')) {
      this.activeName = sessionStorage.getItem('currentTab')
    }
    this.isAllSelect = this.activeName === 'Temp'
  },
  methods: {

    AllHidden() {
      this.isAllSelect = false
    },
    changeAll(val) {
      // this.$refs.tabs.changeAll(val)
      // console.log(this.$refs.tabs.$options.methods)
    },
    hiddenSelect(val) {
      this.isDeselect = val
    },
    deselect() {
      this.isAll = false
      if (this.activeName === 'Temp') {
        this.$refs.tabs.deselect()
      }
    },
    showTip(tab) {
      this.isDeselect = false
      this.isAllSelect = tab.name === 'Temp'
      sessionStorage.setItem('currentTab', tab.name)
      this.$nextTick(() => {
        if (this.isDisable) {
          if (tab.name === 'Temp' || tab.name === 'Timer' || tab.name === 'Spm') {
            this.$message({
              showClose: true,
              message: this.$t('Tip.NetCorrect'),
              type: 'warning',
              duration: 1000
            })
          }
        }
      })
    },
    forward(activeName) {
      this.activeName = activeName
    },
    toBack() {
      this.$router.go(-1)
    }
  },
  created() {
    this.name = this.$route.query.name

    this.$nextTick(() => {
      if (parseInt(this.$route.query.status) === 1) {
        this.isDisable = false
      } else {
        this.activeName = 'Record'
        this.isDisable = true
        this.isAllSelect = false
      }
    })
  }
}
</script>

<style lang='less' scoped>
.deselect {
  min-width: 70px;
  padding: 0px 10px;
  height: 30px;
  border-radius: 14.5px;
  background: #ebecee;
  border: 2px solid #4478f3;
  position: absolute;
  right: 170px;
  top: 80px;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  color: #4478f3;
  cursor: pointer;
}

.checkBox {
  position: absolute;
  right: 80px;
  top: 80px;

  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #4478f3;
    border-color: #4478f3;
  }

  /deep/ .el-checkbox__label {
    color: #7e7d7d;
    font-size: 16px;
    line-height: 25px;

  }

  /deep/ .el-checkbox__inner {
    width: 24px;
    height: 24px;
    padding-top: 20px;
    font-weight: bold;

  }

  /deep/ .el-checkbox__inner::after {
    height: 12px;
    left: 7px;
    position: absolute;
    top: 3px;
    width: 6px;
  }
}

.moldMain {
  background: #ebecee;

  /deep/ .el-tabs__header {
    margin: 0px;
    border-bottom: 2px solid rgba(145, 149, 158, 0.45) !important;
  }

  /deep/ .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
    border-bottom: 4px solid #4478f3 !important;
    color: #4478f3;
  }

  /deep/ .el-tabs__item {
    margin-right: 45px;

    border: none !important;
    font-weight: 700;
    font-size: 20px;
    height: 48px;
    background-position: 0px 10px;
    background-repeat: no-repeat;
    padding-right: 0px !important;
  }

  /deep/ .el-tabs__nav {
    border: none !important;
    padding-left: 25px;
  }

  /deep/ .el-tabs--card>.el-tabs__header .el-tabs__item:first-child {
    background-image: url('../../../assets/image/温度-(3)@2x.png');
    background-size: 24px 24px;
    padding-left: 28px !important;
  }

  /deep/ .el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2) {
    background-image: url('../../../assets/image/时间-(2)@2x.png');
    background-size: 24px 24px;
    padding-left: 32px !important;
  }

  /deep/ .el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(3) {
    background-image: url('../../../assets/image/瞄准@2x.png');
    background-size: 28px 28px;
    background-position: 0 8px;
    padding-left: 34px !important;
  }

  /deep/ .el-tabs--card>.el-tabs__header .el-tabs__item:last-child {
    background-image: url('../../../assets/image/record.png');
    background-size: 28px 28px;
    background-position: 0 8px;
    padding-left: 34px !important;
  }

  // /deep/ .el-tabs__item:nth-child(2) {
  //     padding-left: 0px !important;
  // }
  /deep/ .el-tabs__item:last-child {
    padding-right: 0px !important;
  }

  /deep/ .el-tabs__nav-wrap {
    margin-bottom: -3px;
  }

  .moldTop {
    padding: 23px;
    display: flex;

    .backIcon {
      .backImg {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-image: url('../../../assets/image/left.png');
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 7px;
        cursor: pointer;
      }
    }

    .backTitie {
      color: #414141;
      font-size: 24px;
      font-weight: bold;
      float: left;
      text-align: left;
      padding-left: 15px;
    }
  }
}
</style>
