<template>
  <div class="main">
    <div class="app-wrapper">
      <div class="table_content">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          :header-cell-style="{ background: '#dde0e8' }"
          highlight-current-row
          v-show="!noDataTable"
          style="width: 100%"
        >
          <el-table-column width="60" align="center" />
          <el-table-column min-width="16%" :label="$t('Record.ZoneNumber')">
            <template slot-scope="scope">
              <div>
                {{
                  hanleOrderNumber(
                    scope.row.order_number,
                    scope.row.group_number
                  ) +
                  "[" +
                  scope.row.group_number +
                  "]"
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="16%" :label="$t('Record.Temperature')">
            <template slot-scope="scope">
              <div>
                {{ handleTemperature(scope.row.temperature, scope.row.switch)
                }}{{
                  getUnits(
                    scope.row.unit,
                    scope.row.temperature,
                    scope.row.switch
                  )
                }}
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column min-width="16%" :label="$t('Update2.tempOffset')">
            <template slot-scope="scope">
              <div>
                {{ scope.row.deviation || "--"
                }}{{
                  scope.row.deviation ? (scope.row.unit === 1 ? "℉" : "℃") : ""
                }}
              </div>
            </template>
          </el-table-column> -->
          <el-table-column min-width="16%" :label="$t('Record.Switch')">
            <template slot-scope="scope">
              <div>{{ handleSwitch(scope.row.switch) }}</div>
            </template>
          </el-table-column>
          <el-table-column min-width="16%" :label="$t('Record.Modifier')">
            <template slot-scope="scope">
              <div>{{ scope.row.nickname }}</div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="15%"
            :label="$t('Record.ModificationTime')"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.createtime | time }}</div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-container">
          <el-pagination
            background
            layout="prev, pager, next, sizes"
            :page-size="pagesize"
            :page-sizes="[5, 10, 15]"
            :current-page.sync="page"
            :total="total"
            v-show="total && total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
      <div v-show="noDataTable" class="noData">
        <img src="../../../../assets/image/emptyData.png" />
        <div class="empty_text">{{ $t("Warning.NoData") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import "../../../../style/table.less";
import { getRecord } from "@/api/machine";
import { celsiusToFahrenheit, isEmpty } from "../../../../js/util";
export default {
  name: "Machine",
  data() {
    return {
      tableData: [], // 修改记录数组
      total: 0, // 总数
      page: 1,
      pagesize: 15,
      noDataTable: false,
    };
  },
  created() {
    this.getRecord();
  },
  filters: {
    // 将时间戳转换成 xxxx年xx月 ： xx时xx分格式
    time(item) {
      const date = new Date(item * 1000);
      const month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
      const hour =
        date.getHours() >= 10 ? date.getHours() : "0" + date.getHours();
      const minute =
        date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes();
      return (
        date.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + minute
      );
    },
  },
  methods: {
    // 根据原先的编号 展示新的编号
    handleZoneData(data) {
      let { group_number } = item;
      const [pre, next] = group_number.split("-");
      const point = (Number(pre) - 1) * 4 + Number(next);
      item.point = point;
    },
    handleSwitch(data) {
      if (isEmpty(data) || data == "hold") {
        return "--";
      }
      return data;
    },
    // 处理温度
    handleTemperature(temp, status) {
      if (isEmpty(temp)) return "--";
      return Math.round(temp);
    },
    getUnits(unit, temp, status) {
      if (isEmpty(temp)) return "";
      return unit === 1 ? "℉" : "℃";
    },
    // 获取记录API
    getRecord() {
      const params = {};
      params.serial_number = this.$route.query.serial_number;
      const page = this.page;
      const pagesize = this.pagesize;
      const token = localStorage.getItem("token");
      const lang = localStorage.getItem("language");
      getRecord(params, token, page, pagesize, lang).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.count;
        this.noDataTable = this.total === 0;
      });
    },
    hanleOrderNumber(preNumber, group_number) {
      const tempList = JSON.parse(localStorage.getItem("Temp"));
      if (isEmpty(tempList)) return preNumber;
      const targetTemp = tempList.find(
        (temp) => temp.group_number == group_number
      );
      return targetTemp?.name ?? preNumber;
    },
    handleSizeChange(val) {
      this.page = 1;
      this.pagesize = val;
      this.getRecord();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getRecord();
    },
  },
};
</script>

<style lang="less" scoped>
.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;

  position: absolute;
  flex-direction: column;
  left: 0;
  top: 200px;
  .empty_text {
    line-height: 20px !important;
    font-weight: bold;
    font-size: 16px;
    color: #909399;
  }
}
.pagination-container {
  margin: 20px 25px;
  float: left;
  position: absolute;
  bottom: 4px;
}
.app-wrapper /deep/ .el-table__empty-text {
  line-height: 20px !important;
  font-weight: bold;
  font-size: 16px;
  color: #909399;
}
.main {
  width: 100%;
  height: 100%;
  background-color: #ebecee;

  .app-wrapper {
    background-color: #ebecee;
    height: 100%;
    .table_content {
      margin-top: 10px;
      height: calc(100vh - 135px);
      overflow: auto;
      /deep/ .el-table {
        background-color: #ebecee;
        overflow: auto;
        height: calc(100vh - 215px);
      }
      /deep/ .el-table__body-wrapper {
        height: auto;
        overflow-y: auto;
        background-color: #ebecee;
        overflow-x: hidden;
      }
      /deep/ .el-table tr {
        // background-color: #ebecee !important;
        cursor: pointer;
      }
    }
    .machine_title {
      padding: 22px 20px;
      color: #414141;
      font-size: 24px;
      font-weight: 700;
      text-align: left;
    }
    .machine_search {
      display: flex;
      flex-direction: row;
      padding: 0px 25px;
      .machine_search_list {
        margin-right: 20px;
        // flex: 2.5;
        .search_title {
          float: left;
          text-align: left;
          height: 32px;
          line-height: 32px;
          margin: 10px 10px 10px 0;
          color: #4d4f5c;
          font-size: 18px;
        }
        .search_selected {
          float: left;
          margin: 10px 10px 10px 0;
          /deep/ .el-date-editor.el-input,
          /deep/ .el-date-editor.el-input__inner {
            width: 100%;
          }
          /deep/ .el-input__inner {
            height: 32px;
            line-height: 32px;
          }
          /deep/ .el-input__icon {
            margin-top: 5px;
            color: #a4afb7;
            font-weight: bold;
          }
          /deep/ .input-wrapper .el-input__icon {
            margin-top: 2px;
          }
          /deep/ .el-select .el-input .el-select__caret.is-reverse {
            margin-top: -3px;
          }
        }
      }
      .machine_search_btn {
        flex: 0.55;
        .search_btn {
          width: 70px;
          height: 32px;
          background: #4875ef;
          font-size: 13px;
          color: #f2f3f6;
          text-align: center;
          line-height: 32px;
          border-radius: 30px;
          margin: 10px 0px;
          cursor: pointer;
        }
        .edi_color {
          background-color: #767e95;
        }
      }
      .machine_search_space {
        flex: 5.4;
      }
    }
  }
}
</style>
