<template>
  <div class="timer">
    <display-mode
      typeKey="timerActiveComponent"
      class="display_mode"
    ></display-mode>
    <div class="line"></div>
    <div class="table" ref="ltable">
      <div class="table_title">
        <div style="min-width: 4%"></div>
        <div class="table_first">{{ $t("Timer.Item") }}</div>
        <div style="min-width: 11%"></div>
        <div class="table_second">{{ $t("Timer.FirstInjection") }}(S)</div>
        <div style="min-width: 11%"></div>
        <div class="table_third">{{ $t("Timer.SecondInjection") }}(S)</div>
        <div style="min-width: 6%"></div>
      </div>
      <el-table :data="leftList" tooltip-effect="dark">
        <el-table-column min-width="3%" align="center" />
        <el-table-column min-width="10%" :label="$t('Timer.CH')" align="center">
          <template slot-scope="scope">
            <div class="bold">{{ scope.row.number }}</div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="12%"
          :label="$t('Timer.Model')"
          align="center"
        >
          <template slot-scope="scope">
            <div class="bold" :key="9">{{ scope.row.mode }}</div>
          </template>
        </el-table-column>
        <el-table-column min-width="11%" label="" align="center">
        </el-table-column>
        <el-table-column
          min-width="13%"
          :label="$t('Timer.Delay1')"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.first_phase.delay || "0" }}</div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="13%"
          :label="$t('Timer.Open1')"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.first_phase.open || "0" }}</div>
          </template>
        </el-table-column>
        <el-table-column min-width="11%" label="" align="center">
        </el-table-column>
        <el-table-column
          min-width="13%"
          :label="$t('Timer.Delay2')"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.second_phase.delay || "0" }}</div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="13%"
          :label="$t('Timer.Open2')"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.second_phase.open || "0" }}</div>
          </template>
        </el-table-column>
        <el-table-column min-width="6%" label="" align="center">
        </el-table-column>
      </el-table>
    </div>
    <div class="table" ref="ltable">
      <div class="table_title">
        <div style="min-width: 4%"></div>
        <div class="table_first">{{ $t("Timer.Item") }}</div>
        <div style="min-width: 11%"></div>
        <div class="table_second">{{ $t("Timer.FirstInjection") }}(S)</div>
        <div style="min-width: 11%"></div>
        <div class="table_third">{{ $t("Timer.SecondInjection") }}(S)</div>
        <div style="min-width: 6%"></div>
      </div>
      <el-table :data="rightList" tooltip-effect="dark">
        <el-table-column min-width="3%" align="center" />
        <el-table-column min-width="10%" :label="$t('Timer.CH')" align="center">
          <template slot-scope="scope">
            <div class="bold">{{ scope.row.number }}</div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="12%"
          :label="$t('Timer.Model')"
          align="center"
        >
          <template slot-scope="scope">
            <div class="bold" :key="9">{{ scope.row.mode }}</div>
          </template>
        </el-table-column>
        <el-table-column min-width="11%" label="" align="center">
        </el-table-column>
        <el-table-column
          min-width="13%"
          :label="$t('Timer.Delay1')"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.first_phase.delay || "0" }}</div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="13%"
          :label="$t('Timer.Open1')"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.first_phase.open || "0" }}</div>
          </template>
        </el-table-column>
        <el-table-column min-width="11%" label="" align="center">
        </el-table-column>
        <el-table-column
          min-width="13%"
          :label="$t('Timer.Delay2')"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.second_phase.delay || "0" }}</div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="13%"
          :label="$t('Timer.Open2')"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.second_phase.open || "0" }}</div>
          </template>
        </el-table-column>
        <el-table-column min-width="6%" label="" align="center">
        </el-table-column>
      </el-table>
    </div>
    <div v-show="noDataTable" class="noData">
      <img src="../../../../assets/image/emptyData.png" />
      <div class="empty_text">{{ $t("Warning.NoData") }}</div>
    </div>
    <div v-show="checkTable" class="noData">
      <img src="../../../../assets/image/emptyData.png" />
      <div class="empty_text">{{ $t("Tip.isCheck") }}</div>
    </div>
  </div>
</template>

<script>
import displayMode from "../../components/displayMode.vue";
export default {
  name: "Timer",
  components: { DisplayMode: displayMode },
  data() {
    return {
      noDataTable: false, // 暂无数据
      checkTable: false,
      leftList: [], // 左侧数组
      rightList: [], // 右侧数组
    };
  },
  methods: {},
  created() {
    // 从本地存储中获取SPM数据（在temp页面的API里已经获取了数据）
    if (!localStorage.getItem("Timer")) {
      this.noDataTable = false;
      this.checkTable = true;
      return;
    }
    if (localStorage.getItem("Timer")) {
      if (localStorage.getItem("Timer") === "[]") {
        this.noDataTable = true;
        this.checkTable = false;
      }
      const tempList = JSON.parse(localStorage.getItem("Timer"));
      for (var i = 0; i < tempList.length; i++) {
        if (i < tempList.length / 2) {
          this.leftList.push(tempList[i]);
        } else {
          this.rightList.push(tempList[i]);
        }
      }
      this.$nextTick(() => {
        // 初始化赋值
        for (var j = 0; j < this.leftList.length; j++) {
          if (this.leftList[j].mode === "OFF") {
            this.$set(this.leftList[j].first_phase, "delay", "--");
            this.$set(this.leftList[j].first_phase, "open", "--");
            this.$set(this.leftList[j].second_phase, "delay", "--");
            this.$set(this.leftList[j].second_phase, "open", "--");
          } else if (this.leftList[j].mode === "A") {
            this.$set(this.leftList[j].first_phase, "open", "--");
            this.$set(this.leftList[j].second_phase, "delay", "--");
            this.$set(this.leftList[j].second_phase, "open", "--");
          } else if (this.leftList[j].mode === "B") {
            this.$set(this.leftList[j].second_phase, "delay", "--");
            this.$set(this.leftList[j].second_phase, "open", "--");
          }
        }
        for (var K = 0; K < this.rightList.length; K++) {
          if (this.rightList[K].mode === "OFF") {
            this.$set(this.rightList[K].first_phase, "delay", "--");
            this.$set(this.rightList[K].first_phase, "open", "--");
            this.$set(this.rightList[K].second_phase, "delay", "--");
            this.$set(this.rightList[K].second_phase, "open", "--");
          } else if (this.rightList[K].mode === "A") {
            this.$set(this.rightList[K].first_phase, "open", "--");
            this.$set(this.rightList[K].second_phase, "delay", "--");
            this.$set(this.rightList[K].second_phase, "open", "--");
          } else if (this.rightList[K].mode === "B") {
            this.$set(this.rightList[K].second_phase, "delay", "--");
            this.$set(this.rightList[K].second_phase, "open", "--");
          }
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  position: absolute;
  flex-direction: column;
  left: 0;
  top: 120px;
  .empty_text {
    line-height: 20px !important;
    font-weight: bold;
    font-size: 16px;
    color: #909399;
  }
}
.bold {
  font-weight: bold;
  color: #323232;
}

.timer {
  height: calc(100vh - 126px);
  position: relative;
  .display_mode {
    position: absolute;
    right: 10px;
    top: -32px;
  }
  /deep/ .el-table__empty-text {
    display: none;
  }
  .line {
    width: 4px;
    height: 30px;
    background-color: #707070;
    position: absolute;
    right: 50%;
    top: 30px;
    z-index: 100;
  }
  .table {
    width: 50%;
    float: left;
    margin-top: 10px;
    .table_title {
      width: 100%;
      height: 36px;
      line-height: 36px;
      background-color: #bfc2ce;
      padding: 0;
      font-weight: 400;
      color: #323232;
      div {
        float: left;
        height: 100%;
        font-size: 16px;
      }
      .table_first {
        min-width: 20%;
      }
      .table_second {
        min-width: 24%;
      }
      .table_third {
        min-width: 23%;
      }
    }
    /deep/ .el-table__body-wrapper {
      height: calc(100vh - 210px);
      overflow-y: auto;
      overflow-x: hidden;
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 4px;
      height: 4px;
      background-color: #fff;
      cursor: pointer;
    }
    /*定义滚动条轨道 内阴影+圆角*/
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0px rgba(255, 255, 255, 0.5);
      border-radius: 0px;
      background-color: #fff;
    }

    /*定义滑块 内阴影+圆角*/
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      border-radius: 0px;
      box-shadow: inset 0 0 0px rgba(255, 255, 255, 0.5);
      background-color: #bfc2ce;
    }
    /deep/ .el-table thead {
      height: 36px;
      line-height: 36px;
      th {
        background-color: #bfc2ce;
        padding: 0;
        font-weight: 400;
        color: #323232;
      }
    }
  }
}
</style>
