const { fabric } = window;
import i18n from "@/lang";
export class TimerEditor {
  #fontSize;
  #maxWidth;
  #infoPaneHeight = 100;

  constructor(domId, parentNode, pcProps) {
    this.domId = domId;
    this.parentNode = parentNode;
    // width height x y
    this.parentNodeClients = parentNode.getBoundingClientRect();
    console.log(this.parentNodeClients)
    this.pcProps = pcProps || this.parentNodeClients;
    this.init();
    //  获取当前显示尺寸
    this.#fontSize = 16;
    this.isDragging = false;
    this.lastPosX = null;
    this.lastPosY = null;
  }
  getCanvas() {
    return this.canvas;
  }
  init() {
    this.canvas = new fabric.Canvas(this.domId, {
      // 元素对象被选中时保持在当前z轴，不会跳到最顶层
      preserveObjectStacking: true,
      width: this.pcProps.width,
      height: this.pcProps.height,
      selection: false,
      enablePointerEvents: true,
      allowTouchScrolling: true,
      backgroundColor: "#DFDFE1",

    });
    this.columnPer = this.parentNodeClients.width / this.pcProps.width;
    this.rowPer = this.parentNodeClients.height / this.pcProps.height;
    return this;
  }
  // 初始化监听事件
  initEventListener(events, cb) {
    this.canvas.on(events, cb);
  }
  // 移除监听事件
  removeEventListener(events, cb) {
    this.canvas?.off?.(events, cb);
  }

  destroy() {}
  getPreClients(pre) {
    return {
      height: pre.height,
      width: pre.width,
      bl: pre.lineCoords.bl,
    };
  }
  loadPdfImg(img, width, height) {
    const fabricImg = new fabric.Image(img, {
      left: -100,
      top: -100,
      filters: [],
      width,
      height,
      hasBorders: false,
      hasControls: false,
      selectable: true,
      name: "backgroundImage",
      lockMovementX: true,
      lockMovementY: true,
    });

    //调整图片的大小 以及画布的放大比例  让图片自适应展示
    const imgRatio = fabricImg.height / fabricImg.width;
    const parRatio =
      this.pcProps.height / this.pcProps.width;
    if (imgRatio < parRatio) {
      const h = this.pcProps.width * imgRatio * 0.9;
      fabricImg.scaleToHeight(h);
      fabricImg.set({
        top:
          this.pcProps.height / 2 -
          fabricImg.getBoundingRect().height / 2,
        left:
          this.pcProps.width / 2 -
          fabricImg.getBoundingRect().width / 2,
      });
    } else {
      const w = ((this.pcProps.width * parRatio) / imgRatio) * 0.9;
      fabricImg.scaleToWidth(w);
      fabricImg.set({
        top:
          this.pcProps.height / 2 -
          fabricImg.getBoundingRect().height / 2,
        left:
          this.pcProps.width / 2 -
          fabricImg.getBoundingRect().width / 2,
      });
    }
    this.backgroundInstance = fabricImg;
    fabricImg.set({
      shadow: {
        color: "rgba(0, 0, 0, 0.4)",
        offsetX: 4,
        offsetY: 16,
        blur: 40,
      },
      dirty: true,
    });
    this.canvas.add(fabricImg);
    this.canvas.sendToBack(fabricImg);
    return fabricImg;
  }
  async loadPdf(pdfUrl, fileName) {
    const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
    const page = await pdf.getPage(1);
    const temporaryCanvas = document.createElement("canvas");
    const context = temporaryCanvas.getContext("2d");
    const viewport = page.getViewport({ scale: 1 });
    temporaryCanvas.width = viewport.width;
    temporaryCanvas.height = viewport.height;
    await page.render({ canvasContext: context, viewport }).promise;
    const pdfImage = this.loadPdfImg(
      temporaryCanvas,
      viewport.width,
      viewport.height
    );
    if (fileName) {
      this.fileName = new fabric.IText(
        `${i18n.t("FileDisplay.FileName")}: ${fileName}`,
        {
          fill: "#5B5434",
          fontSize: this.#fontSize,
          left: pdfImage.left,
          top: pdfImage.top - 20,
          name: "fileName",
          dirty: true,
          selectable: false,
          hasControls: false,
        }
      );
      this.canvas.add(this.fileName);
    }
    return pdfImage;
  }
  loadImageByUrl(url, fileName) {
    //
    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        url,
        // "http://192.168.3.218:3120/picture_path/75d6a32b8f030374%20(copy).png",
        // "https://img1.baidu.com/it/u=2628517454,3112868049&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500",
        (img) => {
          //调整图片的大小 以及画布的放大比例  让图片自适应展示
          const imgRatio = img.height / img.width;
          const parRatio =
            this.pcProps.height / this.pcProps.width;
          if (imgRatio < parRatio) {
            const h = this.pcProps.width * imgRatio * 0.9;
            img.scaleToHeight(h);
            img.set({
              top:
                this.pcProps.height / 2 -
                img.getBoundingRect().height / 2,
              left:
                this.pcProps.width / 2 -
                img.getBoundingRect().width / 2,
            });
          } else {
            const w =
              ((this.pcProps.width * parRatio) / imgRatio) * 0.9;
            img.scaleToWidth(w);
            img.set({
              top:
                this.pcProps.height / 2 -
                img.getBoundingRect().height / 2,
              left:
                this.pcProps.width / 2 -
                img.getBoundingRect().width / 2,
            });
          }

          this.canvas.add(img);
          this.canvas.sendToBack(img);
          this.backgroundInstance = img;
          img.set({
            shadow: {
              color: "rgba(0, 0, 0, 0.4)",
              offsetX: 4,
              offsetY: 16,
              blur: 40,
            },
            name: "backgroundImage",
            hasBorders: false,
            hasControls: false,
            selectable: true,
            dirty: true,
            lockMovementX: true,
            lockMovementY: true,
          });
          if (fileName) {
            this.fileName = new fabric.IText(
              `${i18n.t("FileDisplay.FileName")}: ${fileName}`,
              {
                fill: "#5B5434",
                fontSize: this.#fontSize,
                left: img.left,
                top: img.top - 20,
                name: "fileName",
                dirty: true,
                hasControls: false,
                selectable: false,
              }
            );
            this.canvas.add(this.fileName);
          }
          resolve(img);
        }
      );
    });
  }

  // 获取状态色
  getColorByStatus(colorConfig, status) {
    return colorConfig[status];
  }
  // 拖拽生成group信息面板
  createNormalGroupPane({ x, y }, source) {
    const circle = new fabric.Circle({
      radius: 8,
      fill: source.initShineColor,
      left: x + 12,
      top: y + 10,
      name: "circleToShine",
      dirty: true,
    });
    const text1 = new fabric.IText(`${source.id}`, {
      fill: "#FFFFFF",
      fontSize: this.#fontSize,
      left: x + 32,
      top: circle.top,
      fontWeight: "bold",
      name: "id",
      dirty: true,
    });

    const text2 = new fabric.IText(`${source.groupName}`, {
      fill: "#FFFFFF",
      fontSize: this.#fontSize,
      left: text1.left + text1.getScaledWidth() + 15,
      top: text1.top,
      fontWeight: "bold",
      name: "groupName",
      dirty: true,
    });

    const text3 = new fabric.IText(
      `${i18n.t("FileDisplay.Mode")} ${source.mode}`,
      {
        fill: "#FFFFFF",
        fontSize: this.#fontSize,
        left: text2.left + text2.getScaledWidth() + 15,
        top: text2.top,
        fontWeight: "bold",
        name: "modeName",
        dirty: true,
      }
    );

    const text4 = new fabric.IText(
      `${i18n.t("FileDisplay.Delay")}1\n${source.delay1}`,
      {
        fill: "#9C9C9C",
        fontSize: this.#fontSize,
        left: circle.left,
        top: circle.top + circle.radius * 2 + 20,
        fontWeight: "bold",
        name: "delay1Name",
        dirty: true,
      }
    );

    const text5 = new fabric.IText(
      `${i18n.t("FileDisplay.Open")}1\n${source.open1}`,
      {
        fill: "#9C9C9C",
        fontSize: this.#fontSize,
        left: text4.left + text4.getScaledWidth() + 10,
        top: text4.top,
        fontWeight: "bold",
        name: "open1Name",
        dirty: true,
      }
    );

    const text6 = new fabric.IText(
      `${i18n.t("FileDisplay.Delay")}2\n${source.delay2}`,
      {
        fill: "#9C9C9C",
        fontSize: this.#fontSize,
        left: text5.left + text5.getScaledWidth() + 10,
        top: text5.top,
        fontWeight: "bold",
        name: "delay2Name",
        dirty: true,
      }
    );

    const text7 = new fabric.IText(
      `${i18n.t("FileDisplay.Open")}2\n${source.open2}`,
      {
        fill: "#9C9C9C",
        fontSize: this.#fontSize,
        left: text6.left + text6.getScaledWidth() + 10,
        top: text6.top,
        fontWeight: "bold",
        name: "open2Name",
        dirty: true,
      }
    );

    this.#maxWidth = Math.max(
      text3.left + text3.getScaledWidth() - x,
      text7.left + text7.getScaledWidth() - x
    );
    const rect = new fabric.Rect({
      left: x, // 距离容器顶部 100px
      top: y, // 距离容器左侧 100px
      fill: source.labelColor, // 填充 橙色
      width: this.#maxWidth, // 宽度 100px
      height: this.#infoPaneHeight, // 高度 100px
      rx: 10, // x轴的半径
      ry: 10, // y轴的半径
      name: "labelBox",
      dirty: true,

      // opacity: 0.6,
    });
    var clipPath = new fabric.Rect({
      left: -this.#maxWidth / 2 - 2.5,
      width: 10,
      height: this.#infoPaneHeight,
      top: -this.#infoPaneHeight / 2,
      fill: source.labelColor,
      dirty: true,
    });

    rect.clipPath = clipPath;
    const rect2 = new fabric.Rect({
      left: x, // 距离容器顶部 100px
      top: y, // 距离容器左侧 100px
      fill: source.initColor, //
      width: this.#maxWidth + 20, // 宽度 100px
      height: this.#infoPaneHeight, // 高度 100px
      rx: 10, // x轴的半径
      ry: 10, // y轴的半径
      name: "wrapperBox",
      dirty: true,
    });
    const group = new fabric.Group(
      [rect2, rect, text1, text2, text3, text4, text5, text6, text7, circle],
      {
        id: source.id,
        name: "infoPane",
        selectionRadius: 10,
        hasControls: false,
        hasControls: false,
        hasBorders: false,
        dirty: true,
        shadow: {
          color: "rgba(0, 0, 0, 0.2)",
          offsetX: 1,
          offsetY: 10,
          blur: 10,
        },
        opacity: 0.9,
      }
    );
    return {
      idText: text1,
      groupNameText: text2,

      modeNameText: text3,
      delay1NameText: text4,
      open1NameText: text5,
      delay2NameText: text6,
      open2NameText: text7,
      labelBox: rect,
      wrapperBox: rect2,
      shineCircle: circle,
      groupData: group,
    };
  }
  createSmallGroupPane({ x, y }, source) {
    const group = this.createNormalGroupPane({ x, y }, source);
    group.delay1NameText.set({
      opacity: 0,
    });
    group.open1NameText.set({
      opacity: 0,
    });
    group.delay2NameText.set({
      opacity: 0,
    });
    group.open2NameText.set({
      opacity: 0,
    });
    group.modeNameText.set({
      left: group.delay1NameText.left,
      top: group.delay1NameText.top,
    });
    group.modeNameText.setCoords();

    group.wrapperBox.set({
      height: 80,
      width:
        group.groupNameText.lineCoords.br.x +
        group.groupData.getCenterPoint().x -
        group.groupData.left +
        10,
    });
    group.labelBox.set({
      height: 80,
    });
    return group;
  }
  createCircle({ x, y }, source) {
    return new fabric.Circle({
      radius: source.circleWidth,
      fill: source.circleColor,
      left: x - 13,
      top: y - 13,
      hasControls: false,
      id: source.id,
      name: "endCircle",
      hasBorders: false,
      opacity: 0.8,
      dirty: true,
    });
  }

  calculateAngle({ x: x1, y: y1 }, { x: x2, y: y2 }) {
    var angle = (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI;
    if (angle < 0) return angle < 0 ? 360 + angle : angle;
    return angle;
  }
  calcuDistance({ x: x1, y: y1 }, { x: x2, y: y2 }) {
    return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
  }
  createLine(start, end, { id, lineWidth, lineColor }) {
    const leader_line = new fabric.Line(null, {
      stroke: lineColor,
      strokeWidth: lineWidth,
      selectable: false,
      id,
      name: "leader_Line",
      hasControls: false,
      dirty: true,
    });
    const startPos = start.getCenterPoint();
    const endPos = end.getCenterPoint();
    const angle = this.calculateAngle(startPos, endPos);
    const distance = this.calcuDistance(startPos, endPos);
    leader_line.set({
      x1: startPos.x,
      y1: startPos.y,
      x2: startPos.x + distance,
      y2: startPos.y,
      angle,
    });
    return leader_line;
  }

  // createNormalGroupPane
  createFullData(pos, source) {
    pos = this.canvas.restorePointerVpt(pos);
    const startGroup = this.createNormalGroupPane(
      { x: pos.x - 350, y: pos.y - 46.5 },
      source
    );
    const { groupData, wrapperBox } = startGroup;
    const end = this.createCircle(pos, source);
    const leader_line = this.createLine(groupData, end, source);
    this.canvas.add(leader_line, groupData, end);
    return { startGroup, end, line: leader_line };
  }
  createFullDataBySavedData(startPos, endPos, source) {
    const startGroup = this.createNormalGroupPane(
      { x: startPos.x, y: startPos.y },
      source
    );
    const { groupData } = startGroup;
    const end = this.createCircle(endPos, source);
    const leader_line = this.createLine(groupData, end, source);
    this.canvas.add(leader_line, groupData, end);
    return { startGroup, end, line: leader_line };
  }
  updateLinePos(start, end, line, wrapperBox, curSize) {
    const wrapperBoxWidth = wrapperBox.width;
    const startCenterPoint = {
      x: curSize ? start.left + wrapperBoxWidth / 2 : start.getCenterPoint().x,
      y: start.getCenterPoint().y,
    };
    const endCenterPoint = end.getCenterPoint();
    const angle = this.calculateAngle(startCenterPoint, endCenterPoint);
    const distance = this.calcuDistance(startCenterPoint, endCenterPoint);
    line.set({
      x1: startCenterPoint.x,
      y1: startCenterPoint.y,
      x2: startCenterPoint.x + distance,
      y2: startCenterPoint.y,
      angle,
    });
  }
  setFileName(name) {
    const img = this.getBackgroundInstance();
    this.fileName = new fabric.IText(
      `${i18n.t("FileDisplay.FileName")}: ${name}`,
      {
        fill: "#5B5434",
        fontSize: this.#fontSize,
        left: img.left,
        top: img.top - 20,
        name: "fileName",
        dirty: true,
        selectable: false,
        hasControls: false,
      }
    );
    this.canvas.add(this.fileName);
  }
  changeFileName(name) {
    this.fileName.set({
      text: `${i18n.t("FileDisplay.FileName")}: ${name}`,
    });
    this.canvas.requestRenderAll();
  }

  setBackgroundInstance(instance) {
    this.canvas.add(instance);
    this.backgroundInstance = instance;
    return this;
  }
  getBackgroundInstance() {
    let target;
    this.canvas.forEachObject((item) => {
      if (item.name == "backgroundImage") {
        target = item;
      }
    });
    return target;
  }
  cloneBackgroundInstance() {
    return new Promise((resolve) => {
      this.getBackgroundInstance().clone(
        (instance) => {
          resolve(instance);
        },
        ["name", "hasBorders", "hasControls"]
      );
    });
  }
  getMaxWidth() {
    return this.#maxWidth;
  }
  setMaxWidth(val) {
    this.#maxWidth = val;
    return this;
  }
}
